import React from 'react';
// --------------Privacypolice---Scss-----------
import "../../Stylesheets/PrivacyPolicy/privacypolicy.scss";

// -----------Json-----------------
import Datas from "../../Json/PrivacyPolicy/PrivacyPolicy"





// --------------Footer-----------------
import Footer from '../Footer';
const AllDatas = Datas;

const PrivacyPolicy = () => {
  return (
    <div className='container-fluid' id="PrivacyPolice">
      <div className='  container PrivacyWrap'>
        {AllDatas.Heading.map((items) => (
          <h6 className='text-center' key={items.id}>
            {items.Heading}
            <br /> {items.SecondHeading}
          </h6>
        ))}


        <div className='container' id="ContentPart">
          {/* ----------CompanyRegisters--------------- */}
          <div className='inner-Container'>
            {AllDatas.CompanyRegister.map((items) => (
              <div key={items.id}>
                <p>{items.RegisterPara}</p>
                <br />
                <p>{items.Important_p}</p>
              </div>

            ))}
            {/* --------------information--collect-------- */}
            <div>
              {AllDatas.Information_Collect.About_Infor.map((item) => (
                <div key={item.id}>
                  <h6>{item.Heading}</h6>
                </div>

              ))}
            </div>
            {/* ---------Personal--data--------------------- */}
            <div className='PersonalData'>
              {AllDatas.Information_Collect.PersonalData.PersonalDatas.map((item) => (
                <div key={item.id}>
                  <p><span className='Titles'>{item.title}</span>{item.content} </p>
                  <br />
                  <p>{item.CollectInfo}</p>
                </div>
              ))}
            </div>

            {/* ------Listed---the---Collected---data---------- */}
            <div className='inner-Listed'>
              {AllDatas.Information_Collect.PersonalData.Listed.map((item) => (
                <ul className='Ordered'>
                  <li key={item.id}>
                    <span className='Titles'>{item.titel}</span>
                    {item.description}
                  </li>
                </ul>
              ))}
            </div>
            {/* -----------How----We---Collect---data---------- */}
            <div className='CollectingData'>
              {AllDatas.How_We_Collect_PD.Heading.map((item) => (
                <div key={item.id}>
                  <h5>{item.Heading}</h5>
                  <p>{item.SubHeading}</p>

                </div>
              ))}
            </div>
            {/* ----------------List---of--WAYS---------------- */}
            <div className='listedWAY'>
              {AllDatas.How_We_Collect_PD.Listed.map((item) => (
                <ul className='ListedWAYS'>
                  <li key={item.id}>
                    <span className='Titles'>{item.titel}</span>
                    {item.description}
                  </li>
                </ul>
              ))}
            </div>

            <div className='container'>
              {AllDatas.LegalBases.Heading.map((item) => (
                <div key={item.id}>
                  <h6>{item.heading}</h6>
                  <p>{item.description}</p>
                </div>
              ))}
              <div className='row test justify-content-center'>
                   
               {/* ----------------Description----------------- */}
                <div className='col custom-oders'>
                  {AllDatas.LegalBases.TablePart.Header1.map((item) => (
                    <div className='InnerCol' >{item.head1}</div>
                  ))}
                  <div className='row innerROW'>
                    {AllDatas.LegalBases.TablePart.descriptions.map((items) => (
                      <div className='col-12 custom-oder test' key={items.id}>
                        <p>{items.Datas}</p>
                      </div>
                    ))}
                  </div>
                </div>
            {/* ----------------TypeOfData----------------- */}
                <div className='col custom-oders'>
                  {AllDatas.LegalBases.TablePart.Header2.map((item) => (
                    <div className='InnerCol' >{item.head2}</div>
                  ))}
                  <div className='row  innerROW'>
                     

                     
                        {AllDatas.LegalBases.TablePart.TypeOfData.type1.map((item)=>(
                            <div className='col-12  custom-oder '>
                              
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              <li>{item.Types3}</li>
                          
    
                            </ul>
                            </div>
                      
                        ))}
                        {/* ----Type2----------------- */}
                        {AllDatas.LegalBases.TablePart.TypeOfData.type2.map((item)=>(
                            <div  className='col-12  custom-oder'>
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              
    
                            </ul>
                            </div>
                      
                        ))}
                         {/* ----Type3----------------- */}
                         {AllDatas.LegalBases.TablePart.TypeOfData.type3.map((item)=>(
                            <div  className='col-12  custom-oder'>
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              
    
                            </ul>
                            </div>
                      
                        ))}
                         {/* ----Type4----------------- */}
                         {AllDatas.LegalBases.TablePart.TypeOfData.type4.map((item)=>(
                            <div  className='col-12  custom-oder'>
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              
    
                            </ul>
                            </div>
                      
                        ))}
                         {/* ----Type5----------------- */}
                         {AllDatas.LegalBases.TablePart.TypeOfData.type5.map((item)=>(
                            <div  className='col-12  custom-oder'>
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              <li >{item.Types3}</li>
                              <li>{item.Types4}</li>
                              
                              
    
                            </ul>
                            </div>
                      
                        ))}
                         {/* ----Type6----------------- */}
                         {AllDatas.LegalBases.TablePart.TypeOfData.type6.map((item)=>(
                            <div className='col-12  custom-oder' >
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              
    
                            </ul>
                            </div>
                      
                        ))}

                        {/* ----Type7----------------- */}
                        {AllDatas.LegalBases.TablePart.TypeOfData.type7.map((item)=>(
                            <div className='col-12  custom-oder' >
                            <ul key={item.id}>
                              <li >{item.Types1}</li>
                              <li>{item.Types2}</li>
                              
    
                            </ul>
                            </div>
                      
                        ))}

                      
                      
                     
                   
                  </div>
                </div>
            {/* ----------------processing----------------- */}
                <div className='col custom-oders'>
                  {AllDatas.LegalBases.TablePart.Header3.map((item) => (
                    <div  className='InnerCol'>{item.head3}</div>
                  ))}
                  <div className='row  innerROW'>

                      
                       {AllDatas.LegalBases.TablePart.processing.type1.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                          <li>{item.lists2}</li>
                          <li>{item.lists3}</li>
                          <li>{item.lists4}</li>

                        </ul>

                      </div>
                       ))}
                       {/* ----------------type2----------------------- */}
                       {AllDatas.LegalBases.TablePart.processing.type2.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                        </ul>

                      </div>
                       ))}
                       {/* ----------------type3----------------------- */}
                       {AllDatas.LegalBases.TablePart.processing.type3.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                        </ul>

                      </div>
                       ))}
                       {/* ----------------type4----------------------- */}
                       {AllDatas.LegalBases.TablePart.processing.type4.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                        </ul>

                      </div>
                       ))}

                       {/* ----------------type5----------------------- */}
                       {AllDatas.LegalBases.TablePart.processing.type5.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                          <li >{item.lists2}</li>
                          <li >{item.lists3}</li>
                        </ul>

                      </div>
                       ))}
                        {/* ----------------type6----------------------- */}
                        {AllDatas.LegalBases.TablePart.processing.type6.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                         
                        </ul>

                      </div>
                       ))}
                         {/* ----------------type7----------------------- */}
                         {AllDatas.LegalBases.TablePart.processing.type7.map((item)=>(
                        <div className='col-12  custom-oder'>
                          <ul key={item.id}>
                          <li >{item.lists1}</li>
                         
                        </ul>

                      </div>
                       ))}
                     
                        



                     
                 
                  </div>

                </div>
                </div>
              {/* -----------Content---------------------- */}
              {AllDatas.LegalBases.Content.map((item) => (
                <div key={item.id} className='my-3'>
                  <p>{item.content}</p>
                </div>
              ))}

            </div>


            {/* ------------------------thirdparties------------------------- */}
            <div className='container'>
              {AllDatas.thirdparties.Header.map((item) => (
                <div key={item.id} >
                  <h5>{item.head}</h5>
                  <p>{item.description}</p>
                </div>
              ))}
              <div className='Listed'>
                {AllDatas.thirdparties.list.map((item) => (
                  <ul key={item.id}>
                    <li>{item.list}</li>
                  </ul>
                ))}
              </div>
            </div>

            {/* ------Google----Analytics-------------- */}
            <div className='Container'>
              {AllDatas.thirdparties.GoogleAnalytics.map((item) => (
                <div className='Analytics'>

                  <p><span className='Titles'>{item.Head}</span> {item.description}</p>

                  <p>{item.description1} <a href='https://tools.google.com/dlpage/gaoptout/'>{item.link} </a></p>
                  <p>{item.description2} <a href='https://myadcenter.google.com/personalizationoff?hl=en&sasb=true&ref=ad-settings'>{item.link}</a>
                    {item.description3}  <a href='https://support.google.com/My-Ad-Center-Help/answer/12155656?visit_id=638333067693527286-4294233186&rd=1'>{item.link}</a>

                    {item.description4}</p>

                </div>
              ))}

            </div>
            {/* -------Overseastransfers------------------ */}
            <div className='container'>
              {AllDatas.Overseastransfers.Header.map((item) => (
                <div className='Inner-Tranfer' key={item.id}>
                  <h5>{item.head}</h5>
                  <p>{item.description}</p>
                </div>
              ))}

              {/* ------Includes-------------------------- */}
              {AllDatas.Overseastransfers.includes.map((item) => (
                <div key={item.id}>
                  <span>{item.head}</span>
                  <ul>
                    <li>{item.Unorder1}</li>
                    <li>{item.Unorder2}</li>
                  </ul>
                </div>
              ))}

              {/* ----------Data---retention----------------------------- */}
              <div className="container">
                {AllDatas.Overseastransfers.ListData.Data1.map((item) => (
                  <div>
                    <h5>{item.Head}</h5>
                    <p>{item.description} </p>
                    <p>{item.description1}</p>
                  </div>
                ))}
              </div>
    
              </div>

              {/* ---------------Rights----------------- */}
              <div className='container'>
               


                {/* ------------Choice----------------- */}
                <div>
                  {AllDatas.Overseastransfers.ListData.Rights.Header.map((item) => (
                    <div key={item.id}>
                      <h5>{item.head}</h5>
                    </div>
                  ))}
                  <div className='my-3'>
                    {AllDatas.Overseastransfers.ListData.Rights.list.map((item) => (
                      <div key={item.id} >
                        <p><span className='Titles'>{item.Head}</span>{item.description} </p>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
    {/* -----------------Storage----And----Others-------------- */}
       <div className='container'>
        {/* ------Storage----------------- */}
        <div>
          {AllDatas.Storage.map((item)=>(
             <div key={item.id}>
              <h5>{item.Head}</h5>
              <p className='my-3'> {item.description}</p>
              <p className='my-2'>{item.description1}</p>
              </div>
          ))}
        </div>
        {/* ----------------------Cookies-------------- */}
        <div>
          {AllDatas.Cookies.map((item)=>(
            <div key={item.id}>
              <h5>{item.head}</h5>
              <p className='my-3'>{item.description} <a href='https://www.deaneinvestment.com/'>{item.Link}</a></p>
              <p className='my-2'>{item.description1}</p>
              </div>
          ))}
        </div>
       
       {/* -----Amendments-------------------- */}
       <div>
        {AllDatas.Amendments.map((item)=>(
          <div key={item.id}>
            <h5>{item.Head}</h5>
            <p className='my-3'>{item.description}</p>
            </div>
        ))}
       </div>
    {/* -------------------Notices----------------- */}
    <div>
      {AllDatas.notices.map((item)=>(
        <div key={item.id}>
          <h5>{item.head}</h5>
          <p className=' my-3'> {item.Details}</p>
           <p className=' my-3'>{item.Details2}<a href='mailto:sales@deaneholdings.com'>{item.email}</a></p>
           <p className=' my-3'><span>{item.LastUp}</span> {item.update}</p>
           <p>{item.Copy}<a href='#'>{item.link}</a> {item.Name}</p>
          </div>
      ))}
    </div>

      
          



       </div>   





        </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default PrivacyPolicy
