import "../../Stylesheets/projects/Intro_pics.scss";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { useEffect, useState } from "react";

function Intro_pic(props) {
  const listselect = useSelector((state) => state.counter.list);
  let count = 0;
  const [Url, setUrl] = useState("");

  console.log(listselect, "head");

  useEffect(() => {
    const value = window.location.href.split("/");
    console.log(value[3], "value");
    setUrl(value[3]);
    const observe1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          props.setViewItem(true);
        } else {
          props.setViewItem(false);
        }
      });
    });

    observe1.observe(document.querySelector(".viewText"));
  }, []);

  // const handleEvent = () => {
  // const div = document.getElementById("Inner_text");
  // let val = $(window).scrollTop();
  // if (div && count % 2 == 0) {
  //   div.style.marginTop = val + "px";
  //   count = count + 1;
  // }
  // };

  // window.addEventListener("scroll", handleEvent);

  return (
    <div className="cont1 w-100">
      <div className="projects_background">
        <div
          className="project-text"
          id="Inner_text"
          // style={{ transition: "10s" }}
        >
          {/* {listselect != "Completed projects" 
            ? "Available Projects"
            : "Completed Projects"} */}

          {/* {listselect} */}
          {Url == "completedprojects"
            ? "Completed Projects"
            : Url == "development"
            ? "Development"
            : Url == "fullyrefurbished"
            ? "Fully refurbished"
            : Url == "requiringrefurbishment"
            ? "Requiring Refurbishment"
            : ""}
          <p
            className="viewText"
            style={{ height: "0px", padding: "0px", margin: "0px" }}
          ></p>
        </div>
      </div>
    </div>
  );
}
export default Intro_pic;
