import React from "react";
import banner from "../assets/images/large/boomerang-contact-flash.jpg";
import "../Stylesheets/banner.scss";

function Banner() {
  return (
    <>
      {/* <div className=''></div> */}

      <div className="main_contact_wrapper">
        <div className="card contact-card" style={{ opacity: "0" }}>
          {/* <img src={banner} /> */}
          <div className="Inner_Content">
            <h2 className="Header">Contact Us</h2>
            <h7 style={{ fontSize: "14px !important", fontWeight: "400 " }}>
              Embark on your buy-to-let journey with one of the UK's premier
              property investment firms.
            </h7>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
