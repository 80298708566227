import React, { useState } from "react";
// import Footer from './Footer'
import House_project from "./projects/House_project";
import Intro_pic from "./projects/Intro_pic";
import Project_list from "./projects/Project_list";
import Project_Card from "./projects/projectscard";
import Footer from "./Footer";
export default function Project(props) {
  const loaderchange = (arg) => {
    props.loaderchange(arg);
  };

  const [viewItem, setViewItem] = useState(true);

  return (
    <div
      // className="container-xxl"
      style={{ position: "relative" }}
    >
      <Intro_pic viewItem={viewItem} setViewItem={setViewItem}></Intro_pic>
      <Project_Card loaderchange={loaderchange} viewItem={viewItem} />
      {/* <House_project></House_project> */}
      {/* <Project_list></Project_list> */}
      <Footer></Footer>
    </div>
  );
}
