import React from "react";
import "../../Stylesheets/projects/projectdashboard.scss";
import Banner from "../Banner";
import Contact from "../Contact";
import Footer from "../Footer";
import innovative from "../../assets/images/Innovative Approach to Success.webp";

import { useSelector, useDispatch } from "react-redux";
import { list } from "../../redux/reducer/counterslice";
import refurb from "../../assets/images/properties/Image 3-Sample refurb required property.jpg";
// import fullyrefurb from "../../assets/images/properties/Image 4-Sample fully refurbed property.png";
import fullyrefurb from "../../assets/images/properties/fullyrefurbished.jpeg";
import develope from "../../assets/images/properties/Image 5-Sample development property.jpg";
import complete from "../../assets/images/properties/Image 6-Completed project 3.jpg";

const Projectdashboard = () => {
  const listselect = useDispatch();

  const projectlist = (e, text) => {
    listselect(list(text));
    localStorage.setItem("currentPage2", 1);
    localStorage.setItem("pageNo", 1);
    localStorage.setItem("laststateid", "");
      window.location.href = `/${text.split(" ").join("")}`;
    // window.location.href = "/Projects";
  };

  return (
    <>
      <div className="card_parent_dashboard">
        <div className="main_contact_wrapper">
          <div className="card contact-card">
            {/* <img src={banner} /> */}
            <div className="prop-banner-innerwrap">
              <div className="row">
                <div className="col-md-6 left_content">
                  <ul style={{ marginBottom: "0px", paddingLeft: "15px" }}>
                    <li style={{ marginBottom: "10px" }}>
                      <h7
                        style={{
                          fontSize: "14px !important",
                          fontWeight: "400",
                        }}
                      >
                        REQUIRING REFURBISHMENT
                      </h7>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <h7
                        style={{
                          fontSize: "14px !important",
                          fontWeight: "400 ",
                        }}
                      >
                        FULLY REFURBISHED
                      </h7>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <h7
                        style={{
                          fontSize: "14px !important",
                          fontWeight: "400 ",
                        }}
                      >
                        DEVELOPMENT
                      </h7>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <h7
                        style={{
                          fontSize: "14px !important",
                          fontWeight: "400 ",
                        }}
                      >
                        COMPLETED PROJECTS
                      </h7>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 Inner_Content">
                  <h2 className="Header">Projects</h2>
                  <h7
                    style={{ fontSize: "14px !important", fontWeight: "400 " }}
                  >
                    Embark on your buy-to-let journey with one of the UK's
                    premier property investment firms.
                  </h7>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-inner" style={{ marginTop: "100px" }}>
          <div className="container">
            <div className="row">
              <div class="col-lg-6 col-md-6 col-sm-12 card-wrap">
                <div className="card activate">
                  <div
                    className="card-body"
                    onClick={(e) => projectlist(e, "requiring refurbishment")}
                  >
                    <img
                      src={refurb}
                      className="w-100"
                      style={{ width: "100%", height: "350px" }}
                    ></img>

                    <div className="card-top">
                      <h4 className="card-topic">
                        {/* <p className="card-heading-one">Properties</p> */}
                        <p className="card-heading-two">
                          REQUIRING REFURBISHMENT
                        </p>
                      </h4>
                      <div className="underline"></div>
                    </div>
                    <div className="text">
                      <p>
                        Here is a selection of properties requiring
                        refurbishment at attractive rates of expected return.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 card-wrap">
                <div className="card activate">
                  <div
                    className="card-body"
                    onClick={(e) => projectlist(e, "fully refurbished")}
                  >
                    <img
                      src={fullyrefurb}
                      className="w-100"
                      style={{ width: "100%", height: "350px" }}
                    ></img>
                    <div className="card-top">
                      <h4 className="card-topic">
                        {/* <p className="card-heading-one">FULLY REFURBISHED</p> */}
                        <p className="card-heading-two">FULLY REFURBISHED</p>
                      </h4>

                      <div className="underline"></div>
                    </div>
                    <div className="text">
                      <p>
                        Choosing the right property allows investors to acquire
                        fully refurbished properties that deliver high expected
                        rental yields.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 card-wrap">
                <div className="card activate">
                  <div
                    className="card-body"
                    onClick={(e) => projectlist(e, "development")}
                  >
                    <img
                      src={develope}
                      className="w-100"
                      style={{ width: "100%", height: "350px" }}
                    ></img>
                    <div className="card-top">
                      <h4 className="card-topic">
                        {/* <p className="card-heading-one">Properties For</p> */}
                        <p className="card-heading-two">DEVELOPMENT</p>
                      </h4>

                      <div className="underline"></div>
                    </div>
                    <div className="text">
                      <p>
                        Property for development calls for higher initial
                        investment but can deliver the most attractive returns
                        to reward the initial capital invested.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 card-wrap">
                <div className="card activate">
                  <div
                    className="card-body"
                    onClick={(e) => projectlist(e, "completed projects")}
                  >
                    <img
                      src={complete}
                      className="w-100"
                      style={{ width: "100%", height: "350px" }}
                    ></img>
                    <div className="card-top">
                      <h4 className="card-topic">
                        {/* <p className="card-heading-one">COMPLETED</p> */}
                        <p className="card-heading-two">COMPLETED PROJECTS</p>
                      </h4>

                      <div className="underline"></div>
                    </div>
                    <div className="text">
                      <p>
                        For more than 20 years Deane Investment has successfully
                        completed property projects. A sample of these can be
                        found here.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </>
  );
};

export default Projectdashboard;
