import React from "react";
import "../Stylesheets/property.scss";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { propertyget } from "../apidata";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { MapContainer } from "react-leaflet/MapContainer";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { TileLayer, Marker } from "react-leaflet";
import icon from "../assets/location-icon.png";
import iconShadow from "../assets/location-icon.png";
import rail from "../assets/images/railway-station_3660388.png";
import { useNavigate } from "react-router-dom";
import noimg from "../assets/images/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg";
import $ from "jquery";
import { propertyid } from "../redux/reducer/counterslice";
import arrow from "../assets/images/small/back-arrow.png";
import ImageGallery from "react-image-gallery";
import cameraicon from "../assets/images/camer_icon.png";

export default function Property2(props) {
  const Propertyid = useSelector((state) => state.counter.propertyid);
  const dispatchid = useDispatch();

  const [getdata, setGetData] = useState([]);
  const [long, setlong] = useState(0);
  const [lat, setlat] = useState(0);
  const [tag, settag] = useState(true);
  const [tag2, settag2] = useState(false);
  const [image, setimage] = useState(false);
  const [image1, setimage1] = useState(false);
  const [Url, setUrl] = useState("");

  const [imagedata, setimagedata] = useState([]);
  const [imagedata2, setimagedata2] = useState([]);
  const [imagecaro, setImagecaro] = useState([]);
  const [imageStat, setImageStat] = useState(false);

  console.log(imagecaro.description, "imagecaro");

  const [indexes, setIndexes] = useState([]); // State for indexes

  const propertydata = useSelector((state) => state.counter.list);

  const agentform = useNavigate();

  console.log(getdata, "jkhk");
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    // console.log({ dynamicWidth: window.innerWidth }, "lllllllllll");
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const markerRef = useRef();
  let index1 = 0;

  console.log(index1, "ind11");
  useEffect(() => {
    // $("html, body").animate({ scrollTop: "0px" }, 100);
    props.loaderchange(true);
    const val = window.location.href.split("/");
    setUrl(val[3]);
    const val1 = window.location.href.split("=");
    axios({
      method: "get",
      url: propertyget + val1[1] + "/",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        setGetData(res.data.data);
        console.log(res.data.data, "lkijiji");
        setlong(res.data.data.longitude);
        setlat(res.data.data.latitude);

        const mediaFiles = res.data.data.media_files;

        const bluePrintFiles = res.data.data.blueprint;

        setTimeout(() => {
          props.loaderchange(false);
        }, 2000);

        res.data.data.media_files != [] &&
          res.data.data.media_files != null &&
          res.data.data.media_files.map((data, index) => {
            setimagedata((oldArray) => [
              ...oldArray,
              {
                original: data,
                thumbnail: data,
                description: `${index + 1} of ${mediaFiles.length}`,
              },
            ]);

            setImagecaro((oldArray) => [
              ...oldArray,
              {
                original: data,
                thumbnail: data,
                description: `${index + 1} of ${mediaFiles.length}`,
              },
            ]);
          });
        res.data.data.blueprint != [] &&
          res.data.data.blueprint != null &&
          res.data.data.blueprint.map((data2, index) => {
            setimagedata((oldArray) => [
              ...oldArray,
              {
                original: data2,
                thumbnail: data2,
                description: `${index + 1} of ${bluePrintFiles.length}`,
              },
            ]);

            setimagedata2((oldArray) => [
              ...oldArray,
              {
                original: data2,
                thumbnail: data2,
                description: `${index + 1} of ${bluePrintFiles.length}`,
              },
            ]);
          });
      })
      .catch((error) => {
        console.log(error);
        props.loaderchange(false);
      });
  }, []);
  const minZoomLevel = 2;
  const tileLayerOptions = {
    bounds: [
      [-90, -180],
      [90, 180],
    ], // Set the bounds to restrict the visible area
    // maxZoom: 18,
    tileSize: 512,
    zoomOffset: -1,
    attribution: "Map data © OpenStreetMap contributors",
  };
  let DefaultIcon = L.icon({
    iconUrl: "",
    shadowUrl: "",
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const caroselenter = () => {
    $(".prev_prop").css({ display: "block" });
    $(".next_prop").css({ display: "block" });
  };

  const caroselleave = () => {
    $(".prev_prop").css({ display: "none" });
    $(".next_prop").css({ display: "none" });
  };

  // $(".seemap").click(function () {
  //   $("html,body").animate(
  //     {
  //       scrollTop: $(".map_view").offset().top,
  //     },
  //     100
  //   );
  // });
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  // useEffect(() => {
  //   scrollToElement("40d57b4a-a679-4981-9def-fe6b257a8d7f");
  // }, []);

  return (
    <>
      <div className="container-xxl property_full">
        {/* <div className="header">
        <i class="bi bi-arrow-left" style={{ fontSize: "27px" }}></i>
        <p
          style={{ fontSize: "22px", padding: "1px 15px 0px 15px", margin: 0 }}
          onClick={(e) => {
            agentform("/Projects");
          }}
        >
          Back to Projects
        </p>
      </div> */}
        <div className="main_container">
          {/* <button>Testsdfadsfafafa</button> */}
          <p className="back_to_results">
            <a href={`/${Url}`}>
              <img src={arrow} /> Back to search results
            </a>
          </p>
          {/* <div className="image">
          <img src={logo} style={{ width: "85%" }}></img>
        </div> */}
          <div
            id="carouselExampleControls"
            class="carousel"
            data-bs-ride="carousel"
            data-interval="1000"
            style={{ cursor: "pointer" }}
          >
            <div
              class="carousel-inner"
              onMouseEnter={() => {
                caroselenter();
              }}
              onMouseLeave={(e) => {
                caroselleave();
              }}
              style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "10px" }}
              onClick={() => {
                setImageStat(true);
              }}
            >
              {imagecaro.length > 0 && (
                <button
                  className="outside-page-btn"
                  onClick={() => {
                    console.log(`ind22: ${imagedata.index1 + 1}`);
                  }}
                >
                  <img
                    src={cameraicon}
                    className="cameraicon"
                    alt="Camera Icon"
                  />
                  <span style={{fontWeight:"bold",paddingTop:"5px"}}>{`${getdata.media_files.length}`}</span>
                </button>
              )}
              {screenSize.dynamicWidth > 800 ? (
                getdata.media_files &&
                getdata.media_files.length > 0 &&
                getdata.media_files.map((ele, index) => {
                  console.log(getdata.media_files.length, "ggggg");
                  // console.log(getdata.media_files.length / 3);

                  if (getdata.media_files.length % 3 == 0) {
                    if (index <= getdata.media_files.length / 3 - 1) {
                      // console.log(index, "innn");
                      index1 = index1 + 3;
                      // console.log(index1, "innn");

                      return (
                        <>
                          <div
                            className={`carousel-item ${
                              index == 0 ? " active" : ""
                            }`}
                            onClick={(e) => {
                              setimage(true);
                            }}
                          >
                            <div className="row ">
                              <div className="col-8 p-0">
                                <img
                                  src={
                                    getdata.media_files[index1 - 3] == "" ||
                                    getdata.media_files[index1 - 3] == null
                                      ? ""
                                      : getdata.media_files[index1 - 3]
                                  }
                                  class="d-block w-100"
                                  alt="..."
                                  style={{
                                    borderRadius: "5px",
                                    height: "465px",
                                  }}
                                ></img>
                              </div>
                              <div className="col-4 ">
                                <img
                                  src={
                                    getdata.media_files[index1 - 2] == "" ||
                                    getdata.media_files[index1 - 2] == null
                                      ? ""
                                      : getdata.media_files[index1 - 2]
                                  }
                                  class="d-block "
                                  alt="..."
                                  style={{
                                    marginBottom: "10px",
                                    borderRadius: "5px",
                                    height: "230px",
                                    width: "100%",
                                  }}
                                ></img>
                                <img
                                  src={
                                    getdata.media_files[index1 - 1] == "" ||
                                    getdata.media_files[index1 - 1] == null
                                      ? ""
                                      : getdata.media_files[index1 - 1]
                                  }
                                  class="d-block "
                                  alt="..."
                                  style={{
                                    borderRadius: "5px",
                                    height: "225px",
                                    width: "100%",
                                  }}
                                ></img>
                              </div>
                              {/* {imagecaro.length > 0 && (
                                <button
                                  className="outside-page-btn"
                                  onClick={() => {
                                    console.log(
                                      `ind22: ${imagedata.index1 + 1}`
                                    );
                                  }}
                                >
                                  <img
                                    src={cameraicon}
                                    className="cameraicon"
                                    alt="Camera Icon"
                                  />
                                  <span>{`${(index + 1) * 3} / ${
                                    getdata.media_files.length
                                  }`}</span>
                                </button>
                              )} */}
                            </div>
                          </div>
                        </>
                      );
                    }
                  } else {
                    if (getdata.media_files.length >= 3) {
                      if (index1 <= getdata.media_files.length - 3) {
                        index1 = index1 + 1;

                        return (
                          <>
                            <div
                              className={`carousel-item ${
                                index == 0 ? " active" : ""
                              }`}
                            >
                              <div className="row hello">
                                <div className="col-8 p-0">
                                  <img
                                    src={
                                      getdata.media_files[index] == "" ||
                                      getdata.media_files[index] == null
                                        ? ""
                                        : getdata.media_files[index]
                                    }
                                    class="d-block w-100"
                                    alt="..."
                                    style={{
                                      borderRadius: "5px",
                                      height: "465px",
                                    }}
                                  ></img>
                                </div>
                                <div className="col-4 ">
                                  <img
                                    src={
                                      getdata.media_files[index1] == "" ||
                                      getdata.media_files[index1] == null
                                        ? ""
                                        : getdata.media_files[index1]
                                    }
                                    class="d-block "
                                    alt="..."
                                    style={{
                                      marginBottom: "10px",
                                      borderRadius: "5px",
                                      height: "230px",
                                      width: "100%",
                                    }}
                                  ></img>
                                  <img
                                    src={
                                      getdata.media_files[index1 + 1] == "" ||
                                      getdata.media_files[index1 + 1] == null
                                        ? ""
                                        : getdata.media_files[index1 + 1]
                                    }
                                    class="d-block "
                                    alt="..."
                                    style={{
                                      borderRadius: "5px",
                                      height: "225px",
                                      width: "100%",
                                    }}
                                  ></img>
                                </div>
                                {/* {imagecaro.length > 0 && (
                                  <button
                                    className="outside-page-btn"
                                    onClick={() => {
                                      console.log(
                                        `ind22: ${imagedata.index1 + 1}`
                                      );
                                    }}
                                  >
                                    <img
                                      src={cameraicon}
                                      className="cameraicon"
                                      alt="Camera Icon"
                                    />
                                    <span>{`${index} / ${
                                      getdata.media_files.length - 1
                                    }`}</span>
                                  </button>
                                )} */}
                              </div>
                            </div>
                          </>
                        );
                      }
                    } else {
                      return (
                        <>
                          <div
                            className={`carousel-item ${
                              index == 0 ? " active" : ""
                            }`}
                          >
                            <div className="row">
                              <div className="col-12 p-0">
                                <img
                                  src={ele}
                                  class="d-block w-100"
                                  alt="..."
                                  style={{
                                    borderRadius: "5px",
                                    height:
                                      screenSize.dynamicWidth < 450
                                        ? "265px"
                                        : "465px",
                                  }}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                })
              ) : (
                <>
                  {getdata.media_files &&
                    getdata.media_files.length > 0 &&
                    getdata.media_files.map((ele, index) => {
                      // console.log(ele);
                      return (
                        <>
                          <div
                            className={`carousel-item ${
                              index == 0 ? " active" : ""
                            }`}
                          >
                            <div className="row">
                              <div className="col-12 p-0">
                                <img
                                  src={ele}
                                  class="d-block w-100"
                                  alt="..."
                                  style={{
                                    borderRadius: "5px",
                                    height:
                                      screenSize.dynamicWidth < 450
                                        ? "265px"
                                        : "465px",
                                  }}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              )}
            </div>
            <button
              class="carousel-control-prev prev_prop"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
              onMouseEnter={() => {
                caroselenter();
              }}
              onMouseLeave={(e) => {
                caroselleave();
              }}
            >
              <span
                class="carousel-control-prev-icon "
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next next_prop"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
              onMouseEnter={() => {
                caroselenter();
              }}
              onMouseLeave={(e) => {
                caroselleave();
              }}
            >
              <span
                class="carousel-control-next-icon "
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <div className="main_body_content">
            <div className="row">
              <div className="col-md-12 " style={{ padding: "12px" }}>
                <div className="details1">
                  <div
                    className="content_left"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h1 className="prop_name">{getdata.property_address}</h1>
                    <div class="vl"></div>
                    {/* <i class="bi bi-geo-alt locmark"></i>
                  <p className="seemap">See map</p> */}
                  </div>
                  {/* <div
                    className="content_right"
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <i
                      class="bi bi-share"
                      style={{ padding: "0 10px", fontSize: "25px" }}
                    ></i>
                    <div class="vl2"></div>
                    <i
                      class="bi bi-heart"
                      style={{ padding: "0 10px", fontSize: "25px" }}
                    ></i>
                  </div> */}
                </div>
                <div>
                  <p
                    style={{ textAlign: "left", fontWeight: "500" }}
                    className="m-0 p-0"
                  >
                    {getdata.dropdown_for_banners}
                  </p>
                </div>
                <div className="details2">
                  <p className="price">
                    £
                    {getdata.price != ""
                      ? String(getdata.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )
                      : ""}
                  </p>
                  <div className="parent_inner">
                    <div className="addedon">
                      <p>
                        Added on{" "}
                        {String(getdata.created_date)
                          .substring(0, 10)
                          .replaceAll("-", "/")}
                      </p>
                    </div>
                    <div className="req_details">
                      <button
                        className="req_button"
                        onClick={(e) => {
                          //   dispatchid(propertyid(getdata.id));
                          agentform(`/${Url}/AgentForm?id=${getdata.id}`);
                        }}
                      >
                        <i
                          class="bi bi-envelope"
                          style={{ padding: "12px" }}
                        ></i>
                        Request Details
                      </button>
                    </div>
                  </div>
                </div>
                <div className="details3">
                  <div className="propertype">
                    <p style={{ margin: 0 }}>PROPERTY TYPE</p>
                    <div
                      className="house"
                      style={{ display: "flex", color: "black" }}
                    >
                      <i
                        class="bi bi-house-door"
                        style={{ fontSize: "25px" }}
                      ></i>
                      <p
                        style={{
                          margin: "0",
                          padding: "7px",
                          fontSize: "18px",
                        }}
                      >
                        {getdata.property_type}
                      </p>
                    </div>
                  </div>

                  {/* <div className="beadroom">
                    <p style={{ margin: 0 }}>BEDROOM</p>
                    <div
                      className="beadroom_count"
                      style={{ display: "flex", color: "black" }}
                    >
                      <i
                        class="fas fa-bed"
                        style={{ marginRight: "10px", fontSize: "25px" }}
                      ></i>
                      <p style={{ margin: 0, fontSize: "18px" }}>
                        x{getdata.bedrooms}
                      </p>
                    </div>
                  </div> */}
                  {getdata.bedrooms !== 0 && (
                    <>
                      <div className="beadroom">
                        <p style={{ margin: 0 }}>BEDROOM</p>
                        <div
                          className="beadroom_count"
                          style={{ display: "flex", color: "black" }}
                        >
                          <i
                            className="fas fa-bed"
                            style={{ marginRight: "10px", fontSize: "25px" }}
                          ></i>
                          <p style={{ margin: 0, fontSize: "18px" }}>
                            x{getdata.bedrooms}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {getdata.bathrooms !== 0 && (
                    <div className="bathroom">
                      <p style={{ margin: 0 }}>BATHROOM</p>
                      <div
                        className="bathroom_count"
                        style={{ display: "flex", color: "black" }}
                      >
                        <i
                          class="fas fa-bath"
                          style={{ marginRight: "10px", fontSize: "25px" }}
                        ></i>
                        <p style={{ margin: 0, fontSize: "18px" }}>
                          x{getdata.bathrooms}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="tenure">
                    <p style={{ margin: 0 }}>TENURE</p>
                    <div className="tenure_count">
                      <p
                        style={{ margin: 0, fontSize: "18px", color: "black" }}
                      >
                        {getdata.tenure}
                      </p>
                    </div>
                  </div>
                </div>

                {getdata.table_data_details && (
                  <div className="project-table">
                    <div className="container-fluid table-main">
                      <div className="table-heading">
                        <p className="heading-name">Financial illustration</p>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Loan</th>
                              <th scope="col">Your Investment</th>
                              <th scope="col">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">Purchase price</td>
                              <td scope="row">
                                {getdata.table_data_details.purchase_price
                                  .length > 0 &&
                                getdata.table_data_details.purchase_price[0]
                                  .Loan != "0" &&
                                getdata.table_data_details.purchase_price[0]
                                  .Loan != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .purchase_price[0].Loan
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.purchase_price
                                  .length > 0 &&
                                getdata.table_data_details.purchase_price[0]
                                  .your_investment != "0" &&
                                getdata.table_data_details.purchase_price[0]
                                  .your_investment != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .purchase_price[0].your_investment
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.purchase_price
                                  .length > 0 &&
                                getdata.table_data_details.purchase_price[0]
                                  .Total != "0" &&
                                getdata.table_data_details.purchase_price[0]
                                  .Total != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .purchase_price[0].Total
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                            </tr>
                            <tr>
                              {propertydata === "Fully refurbished" ? (
                                <td scope="row">Other cost</td>
                              ) : (
                                <td scope="row">Development cost</td>
                              )}
                              <td scope="row">
                                {getdata.table_data_details.development_cost
                                  .length > 0 &&
                                getdata.table_data_details.development_cost[0]
                                  .Loan != "0" &&
                                getdata.table_data_details.development_cost[0]
                                  .Loan != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .development_cost[0].Loan
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.development_cost
                                  .length > 0 &&
                                getdata.table_data_details.development_cost[0]
                                  .your_investment != "0" &&
                                getdata.table_data_details.development_cost[0]
                                  .your_investment != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .development_cost[0].your_investment
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.development_cost
                                  .length > 0 &&
                                getdata.table_data_details.development_cost[0]
                                  .Total != "0" &&
                                getdata.table_data_details.development_cost[0]
                                  .Total != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .development_cost[0].Total
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"></td>
                              <td scope="row">
                                {getdata.table_data_details.empty_column
                                  .length > 0 &&
                                getdata.table_data_details.empty_column[0]
                                  .Loan != "0" &&
                                getdata.table_data_details.empty_column[0]
                                  .Loan != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details.empty_column[0]
                                        .Loan
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.empty_column
                                  .length > 0 &&
                                getdata.table_data_details.empty_column[0]
                                  .your_investment != "0" &&
                                getdata.table_data_details.empty_column[0]
                                  .your_investment != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details.empty_column[0]
                                        .your_investment
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.empty_column
                                  .length > 0 &&
                                getdata.table_data_details.empty_column[0]
                                  .Total != "0" &&
                                getdata.table_data_details.empty_column[0]
                                  .Total != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details.empty_column[0]
                                        .Total
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"></td>
                              <td scope="row"></td>
                              <td scope="row"></td>
                              <td scope="row"></td>
                            </tr>

                            <tr>
                              <td scope="row">
                                Property value post development
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.property_value
                                  .length > 0 &&
                                getdata.table_data_details.property_value[0]
                                  .Loan != "0" &&
                                getdata.table_data_details.property_value[0]
                                  .Loan != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .property_value[0].Loan
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.property_value
                                  .length > 0 &&
                                getdata.table_data_details.property_value[0]
                                  .your_investment != "0" &&
                                getdata.table_data_details.property_value[0]
                                  .your_investment != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .property_value[0].your_investment
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.property_value
                                  .length > 0 &&
                                getdata.table_data_details.property_value[0]
                                  .Total != "0" &&
                                getdata.table_data_details.property_value[0]
                                  .Total != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .property_value[0].Total
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Profit</td>
                              <td scope="row">
                                {getdata.table_data_details.profit.length > 0 &&
                                getdata.table_data_details.profit[0].Loan !=
                                  "0" &&
                                getdata.table_data_details.profit[0].Loan !=
                                  "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {getdata.table_data_details.profit[0].Loan}
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.profit.length > 0 &&
                                getdata.table_data_details.profit[0]
                                  .your_investment != "0" &&
                                getdata.table_data_details.profit[0]
                                  .your_investment != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details.profit[0]
                                        .your_investment
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.profit.length > 0 &&
                                getdata.table_data_details.profit[0].Total !=
                                  "0" &&
                                getdata.table_data_details.profit[0].Total !=
                                  "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {getdata.table_data_details.profit[0].Total}
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Annual rental</td>
                              {/* <td scope="row">Return</td> */}
                              <td scope="row">
                                {getdata.table_data_details.property_return !=
                                  null &&
                                getdata.table_data_details.property_return
                                  .length > 0 &&
                                getdata.table_data_details.property_return[0]
                                  .Loan != "0" &&
                                getdata.table_data_details.property_return[0]
                                  .Loan != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .property_return[0].Loan
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.property_return !=
                                  null &&
                                getdata.table_data_details.property_return
                                  .length > 0 &&
                                getdata.table_data_details.property_return[0]
                                  .your_investment != "0" &&
                                getdata.table_data_details.property_return[0]
                                  .your_investment != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .property_return[0].your_investment
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td scope="row">
                                {getdata.table_data_details.property_return !=
                                  null &&
                                getdata.table_data_details.property_return
                                  .length > 0 &&
                                getdata.table_data_details.property_return[0]
                                  .Total != "0" &&
                                getdata.table_data_details.property_return[0]
                                  .Total != "" ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {"£"}
                                    </span>
                                    {
                                      getdata.table_data_details
                                        .property_return[0].Total
                                    }
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="'image_row row" style={{ marginTop: "20px" }}>
                  <div className="col-md-6 p-2">
                    <div
                      className="image_container1"
                      onClick={(e) => {
                        // console.log("jjj");
                        setimage1(true);
                      }}
                    >
                      {getdata.blueprint &&
                        getdata.blueprint.length > 0 &&
                        getdata.blueprint.map((img, index) => {
                          // if (getdata.blueprint.length > 6) {
                          //   return (
                          //     <>
                          //       <img
                          //         src={img}
                          //         style={{
                          //           width: "100%",
                          //           height: "100%",
                          //           marginRight: "10px",
                          //           marginBottom: "10px",
                          //           borderRadius: "10px",
                          //         }}
                          //       ></img>

                          //       {getdata.media_files.length - 1 == index ? (
                          //         <div
                          //           className="img_count"
                          //           style={{
                          //             width: "100px",
                          //             height: "100px",
                          //             marginRight: "10px",
                          //             marginBottom: "10px",
                          //             borderRadius: "10px",
                          //           }}
                          //         >
                          //           +{getdata.media_files.length - 6}
                          //         </div>
                          //       ) : (
                          //         ""
                          //       )}
                          //     </>
                          //   );
                          // } else {
                          return (
                            <img
                              src={img}
                              style={{
                                // width: "100%",
                                width: "75%",
                                height: "100%",
                                marginRight: "10px",
                                marginBottom: "10px",
                                borderRadius: "10px",
                              }}
                            ></img>
                          );
                          // }
                        })}
                    </div>
                  </div>
                  <div className="col-md-12 p-2 col-lg-6">
                    <div
                      className="image_container"
                      onClick={(e) => {
                        setimage(true);
                      }}
                    >
                      {getdata.media_files &&
                        getdata.media_files.length > 0 &&
                        getdata.media_files.map((img, index) => {
                          // console.log(getdata.media_files.length, index);
                          if (getdata.media_files.length >= 6) {
                            if (index <= 4) {
                              return (
                                <>
                                  <img
                                    src={img}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                  {index == 4 ? (
                                    <div
                                      className="img_count"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        marginRight: "10px",
                                        marginBottom: "10px",
                                        borderRadius: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {/* +{getdata.media_files.length - 5} */}
                                      <img
                                        src={img}
                                        className="plus-count-img"
                                      ></img>
                                      <span className="plus-count-no">
                                        +{getdata.media_files.length - 5}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            }
                          } else {
                            return (
                              <img
                                src={img}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                              ></img>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
                <div className="key_features">
                  <div className="key_header">
                    <p>Key features</p>
                  </div>
                  <ul
                    style={{
                      margin: 0,
                      padding: 0,
                      height: "100%",
                      textAlign: "left",
                    }}
                  >
                    {getdata.key_features &&
                      getdata.key_features.length > 0 &&
                      getdata.key_features.map((ele) => {
                        return <li className="key_list">{ele}</li>;
                      })}
                  </ul>
                </div>
                <div className="discription">
                  <div className="header_discription">
                    <p>Property description</p>
                  </div>
                  <div className="text">
                    {/* <p>{getdata.description}</p> */}
                    <div
                      className="text_box"
                      dangerouslySetInnerHTML={{
                        __html: getdata.description,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="map_view">
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    {getdata.property_address}
                  </p>
                  <div className="map_main ">
                    {/* {lat != 0 && long != 0 ? (
                    <MapContainer
                      center={[lat, long]}
                      zoom={18}
                      scrollWheelZoom={true}
                      minZoom={minZoomLevel}
                      // animate={true}
                      // markersData={markersData}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        // noWrap={true}
                        {...tileLayerOptions}
                      />
                      <Marker position={[lat, long]} ref={markerRef} />
                    </MapContainer>
                  ) : (
                    <></>
                  )} */}
                  </div>
                  <div className="tags">
                    <div className="tagheader">
                      <button
                        className={`station${tag == true ? " activetag" : ""}`}
                        onClick={(e) => {
                          settag(true);
                          settag2(false);
                        }}
                      >
                        <i
                          class="bi bi-train-front mr-3"
                          style={{ fontSize: "20px" }}
                        ></i>
                        <span>Station</span>
                      </button>
                      <button
                        className={`school${tag2 == true ? " activetag" : ""}`}
                        onClick={(e) => {
                          settag2(true);
                          settag(false);
                        }}
                      >
                        <i
                          class="bi bi-mortarboard mr-3"
                          style={{ fontSize: "20px" }}
                        ></i>
                        <span>School</span>
                      </button>
                    </div>
                    {tag == true ? (
                      <>
                        <div className="parent_st_sc">
                          <p className="neareststation">NEAREST STATIONS</p>
                          <div className="map_content">
                            {getdata.nearest_stations &&
                              getdata.nearest_stations.map((data1) => {
                                return (
                                  <>
                                    <div className="text_content">
                                      {/* <img src={rail} style={{width:"27px",height:"24px",marginRight:"50px"}}></img> */}
                                      <i class="bi bi-train-front mr-3"></i>
                                      <p className="station_name">
                                        {data1.station_name}
                                      </p>
                                      <p
                                        style={{
                                          color: "#4f5064",
                                          textAlign: "right",
                                        }}
                                        className="station_miles"
                                      >
                                        {data1.station}
                                        {" miles"}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    ) : tag2 == true ? (
                      <>
                        <div className="parent_st_sc">
                          <p className="neareststation">NEAREST SCHOOLS </p>
                          <div className="map_content">
                            {getdata.nearest_schools &&
                              getdata.nearest_schools.map((data2) => {
                                // console.log(data2[school1])
                                return (
                                  <>
                                    <div className="text_content">
                                      <i class="bi bi-mortarboard mr-3"></i>
                                      <div className="school_content">
                                        <p>{data2.schoolname}</p>
                                        <div style={{ display: "flex" }}>
                                          <span>Type:{data2.type}</span>
                                          <span
                                            style={{
                                              borderLeft: "1px solid #dedee2",
                                              paddingLeft: "5px",
                                            }}
                                          >
                                            ofSted:{data2.Ofsted}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="miles">
                                        <p style={{ margin: 0 }}>
                                          {data2.school} miles
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {image && (
        <div className="gallery">
          <i
            class="bi bi-x-lg"
            style={{
              fontSize: "35px",
              float: "right",
              zIndex: "999",
              color: "black",
              margin: "10px",
              position: "absolute",
              right: "0",
              cursor: "pointer",
            }}
            onClick={(e) => {
              // console.log("jjj");
              setimage(false);
            }}
          ></i>

          <ImageGallery items={imagedata} fullscreen={true} />
        </div>
      )}

      {image1 && (
        <div className="gallery">
          <i
            class="bi bi-x-lg"
            style={{
              fontSize: "35px",
              float: "right",
              zIndex: "999",
              color: "black",
              margin: "10px",
              position: "absolute",
              right: "0",
              cursor: "pointer",
            }}
            onClick={(e) => {
              // console.log("jjj");
              setimage1(false);
            }}
          ></i>

          <ImageGallery items={imagedata2} fullscreen={true} />
        </div>
      )}

      {imageStat && (
        <div className="gallery">
          <i
            class="bi bi-x-lg"
            style={{
              fontSize: "35px",
              float: "right",
              zIndex: "999",
              color: "black",
              margin: "10px",
              position: "absolute",
              right: "0",
              cursor: "pointer",
            }}
            onClick={(e) => {
              // console.log("jjj");
              setImageStat(false);
            }}
          ></i>

          <ImageGallery items={imagecaro} />
        </div>
      )}
    </>
  );
}
