import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  list: "",
};
export const counterSlice = createSlice({
  name: "counterSlice",
  propertyid:"",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    propertyid: (state, action) => {
      state.propertyid = action.payload;
    },


  },
});

export const { list,propertyid} =
  counterSlice.actions;

export default counterSlice.reducer;
