import "../../Stylesheets/projects/Project_list.scss";
import buy from "../../assets/images/large/boomerang-buy-2-let.jpg";
import Footer from "../Footer";
function Project_list() {
  return (
    <div className="container-xxl list-view">
      <div className="inside-list">
        <img src={buy} />
        <ul>
          <li>Boosts the asset value within short period</li>
          <li>High rental yield</li>
          <li>Unlock your deposit</li>
        </ul>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Project_list;
