import React from 'react'


const PrivacyPolicy = {
    Heading: [
        {
            id: 1,
            Heading: ` DEANE &  CO. HOLDINGS LTD -`,
            SecondHeading: ` PRIVACY POLICY`
        },

    ],
    CompanyRegister: [
        {
            id: 1,
            RegisterPara: `Deane &amp; Co. Holdings Ltd, a company registered in England and Wales, with company number, 13653464 (we, us or our),
            understands that protecting your personal data is important. This Privacy Policy sets out our commitment to protecting the
            privacy of personal data provided to us, or otherwise collected by us when providing our website and property
            management services (Services) or when otherwise interacting with you.`,
            Important_p: `It is important that you read this Privacy Policy together with any other detailed privacy notices we may provide when we
            are collecting or processing personal data about you so that you understand our privacy practices in relation to your data.`,

        }
    ],
    Information_Collect: {
        About_Infor: [
            {
                id: 1,
                Heading: `The information we collect:`,
            }

        ],
        PersonalData: {

            PersonalDatas: [
                {
                    id: 1,
                    title: `Personal data:`,
                    content: `is information that relates to an identified or identifiable individual.`,
                    CollectInfo: `We may collect, use, store and disclose different kinds of personal data about you which we have listed below:`
                }
            ],
            Listed: [
                {
                    id: 1,
                    titel: `Identity Data`,
                    description: `including first name, middle name, last name, title, date of birth, gender, job title, and photographic identification.`
                },
                {
                    id: 2,
                    titel: `Contact Data`,
                    description: `including billing address, delivery address, email address and telephone numbers.`
                },
                {
                    id: 3,
                    titel: `Financial Data`,
                    description: `including bank account details.`
                },
                {
                    id: 4,
                    titel: `Background Verification Data`,
                    description: `including your passport number, driver licence number, photographic identification
            or other details requested as part of our Know Your Customer process to comply with our due diligence
            obligations, anti-money laundering laws and related ongoing monitoring commitments.`,
                },
                {
                    id: 5,
                    titel: `Transaction Data`,
                    description: `including details about payments to you from us and from you to us and other details of
            products and services you have purchased from us or we have purchased from you.`,
                },
                {
                    id: 6,
                    titel: `Technical and Usage Data`,
                    description: `including internet protocol (IP) address, your login data, your browser session and geo-
            location data, device and network information, statistics on page views and sessions, acquisition sources, search
            queries and/or browsing behaviour, information about your access and use of our website, including through the
            use of Internet cookies, your communications with our website, the type of browser you are using, the type of
            operating system you are using and the domain name of your Internet service provider.`,
                },


                {
                    id: 7,
                    titel: `Interaction Data`,
                    description: `including information you provide to us when you participate in any interactive features of our
            Services, including surveys, contests, promotions, activities or events.`,
                },
                {
                    id: 8,
                    titel: `Marketing and Communications Data`,
                    description: `including your preferences in receiving marketing from us and our third
            parties and your communication preferences.`,
                },
                {
                    id: 9,
                    titel: `Professional data`,
                    description: `including where you are a worker of ours or applying for a role with us, your professional
            history such as your previous positions and professional experience.`,
                },
                {
                    id: 10,
                    titel: `Special Categories of Personal Data`,
                    description: `is a special category of personal data that includes details about your race or
            ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union
            membership, information about your health and genetic and biometric data. We do not actively request special
            categories of data about you, nor do we collect any information about criminal convictions and offences. If at any
            time we need to collect special categories of data about you, we will only collect it and use it as required or authorised by law.`,
                },
            ]

        }
    },
    // ------- How we----collect---the -Personal--data-----------
    How_We_Collect_PD: {
        Heading: [
            {
                id: 1,
                Heading: `How we collect personal data:`,
                SubHeading: `We collect personal data in a variety of ways, including:`
            },

        ],
        Listed: [
            {
                id: 1,
                titel: `Directly:`,
                description: `We collect personal data which you directly provide to us, including when you apply for one of our
                properties, through the ‘contact us’ form on our website or when you request our assistance via email, or over
                the telephone.`,
            },
            {
                id: 2,
                titel: `Indirectly:`,
                description: `We may collect personal data which you indirectly provide to us while interacting with us, such as
                when you use our website, in emails, over the telephone and in your online enquiries.`,
            },
            {
                id: 3,
                titel: `From third parties:`,
                description: `We collect personal data from third parties, such as of your use of our website from our
                analytics and cookie providers and marketing providers. See the “Cookies” section below for more detail on the use of cookies.`,
            },

        ]

    },
    // ----------------Legal--Bases----------------------

    LegalBases: {
        Heading: [
            {
                id: 1,
                heading: `Purposes and legal bases for processing:`,
                description: `We collect and process personal data about you only where we have legal bases for doing so under applicable laws. We
                have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal
                bases we rely on to do so. We have also identified what our legitimate interests are where appropriate. Note that we may
                process your personal data for more than one lawful ground depending on the specific purpose for which we are using your
                data. Please reach out to us if you need further details about the specific legal ground we are relying on to process your
                personal data where more than one ground has been set out in the table below.`
            }
        ],
        TablePart: {

            Header1: [

                {
                    id: 1,
                    head1: `Purpose of use / disclosure`,
                },
            ],
            Header2: [
                {
                    id: 2,
                    head2: `Type of Data`,
                },

            ],
            Header3: [
                {
                    id: 3,
                    head3: `Legal Basis for processing`,
                },
            ],

            descriptions: [
                {
                    id: 1,
                    Datas: `To assess whether to offer a property
                to you, including to perform anti-
                money laundering, anti-terrorism,
                sanction screening, fraud and other
                background checks on you.`,
                },
                {
                    id: 2,
                    Datas: `To provide our Services to you,
                including to manage and maintain your
                property.`,
                },
                {
                    id: 3,
                    Datas: `To contact and communicate with you
                about our Services including in
                response to any support requests you
                lodge with us or other enquiries you
                make with us.`,
                },
                {
                    id: 4,
                    Datas: `To contact and communicate with you
                about any enquiries you make with us
                via our website.`,
                },
                {
                    id: 5,
                    Datas: `For internal record keeping,
                administrative, invoicing and billing
                purposes.`,
                },
                {
                    id: 6,
                    Datas: `For analytics, market research and
                business development, including to
                operate and improve our Services,
                associated applications and associated
                social media platforms.`,
                },
                {
                    id: 7,
                    Datas: `For advertising and marketing,
                including to send you promotional
                information about our events and`,
                },

            ],
            TypeOfData: {
                type1: [
                    {
                        id: 1,
                        Types1: `Identity Data`,
                        Types2: `Contact Data`,
                        Types3: `Background verification Data`,

                    },
                ],
                type2: [
                    {
                        id: 2,
                        Types1: `Identity Data`,
                        Types2: `Contact Data`,

                    },
                ],

                type3: [
                    {
                        id: 3,
                        Types1: `Identity Data`,
                        Types2: `Contact Data`,

                    },
                ],
                type4: [
                    {
                        id: 4,
                        Types1: `Identity Data`,
                        Types2: `Contact Data`,

                    },
                ],
                type5: [
                    {
                        id: 5,
                        Types1: `Identity Data`,
                        Types2: `Contact Data`,
                        Types3: `Financial Data`,
                        Types4: `Transaction Data`,

                    },
                ],
                type6: [
                    {
                        id: 6,
                        Types1: `Profile Data`,
                        Types2: `Technical and usage Data`,

                    },
                ],
                type7: [
                    {
                        id: 7,
                        Types1: `Identity Data`,
                        Types2: `Contact Data`,

                    },
                ],









            },
            processing: {

                type1: [

                    {
                        id: 1,
                        lists1: `Performance of a contract with
                you`,
                        lists2: `To comply with a legal obligation`,
                        lists3: `Public interest`,
                        lists4: `Legitimate interests: ensuring we
                do not deal with proceeds of
                criminal activities or assist in any
                other unlawful or fraudulent
                activities for example terrorism`,

                    },
                ],
                type2: [


                    {
                        id: 2,
                        lists1: `Performance of a contract with
                you`,
                    },
                ],
                type3: [


                    {
                        id: 3,
                        lists1: `Performance of a contract with
                you`,
                    },
                ],
                type4: [


                    {
                        id: 4,
                        lists1: `Legitimate interests: to ensure we
                provide the best client experience
                we can offer by answering all of
                your questions.`,
                    },
                ],
                type5: [


                    {
                        id: 5,
                        lists1: `Performance of a contract with
                you`,
                        lists2: `To comply with a legal obligation`,
                        lists3: `Legitimate interests: to recover
                debts due to us and ensure we can
                notify you about changes to our
                terms of business and any other
                administrative points.`,
                    },
                ],
                type6: [


                    {
                        id: 6,
                        lists1: `Legitimate interests: to keep our
                website updated and relevant, to
                develop our business, improve our
                Services and to inform our
                marketing strategy`
                    },
                ],
                type7: [


                    {
                        id: 7,
                        lists1: `Legitimate interests: to develop
                    our Services and grow our business`
                    },
                ],


            }
        },
        Content: [
            {
                id: 1,
                content: `If you have consented to our use of data about you for a specific purpose, you have the right to change your mind at any
                time, but this will not affect any processing that has already taken place. Where we are using your data because we or a
                third party have a legitimate interest to do so, you have the right to object to that use though, in some cases, this may
                mean no longer using our services. Further information about your rights is available below.`,
            }
        ],
    },
    thirdparties: {
        Header: [
            {
                id: 1,
                head: `Our disclosures of personal data to third parties:`,
                description: `We may disclose personal data to:`,
            }
        ],
        list: [
            {
                id: 1,
                list: `our employees, contractors and/or related entities;`,

            },
            {
                id: 2,
                list: `IT service providers, data storage, web-hosting and server providers such as Microsoft Azure and ClickUp;`,

            },
            {
                id: 3,
                list: `marketing or advertising providers;`,

            },
            {
                id: 4,
                list: `professional advisors, bankers, auditors, our insurers and insurance brokers;`,

            },
            {
                id: 5,
                list: `payment systems operators such as stripe &amp; paypal.`,

            },
            {
                id: 6,
                list: `our existing or potential agents or business partners;`,

            },
            {
                id: 7,
                list: `sponsors or promoters of any promotions or competition we run;`,

            },
            {
                id: 8,
                list: `anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;`,

            },
            {
                id: 9,
                list: `courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to
                you;`,

            },
            {
                id: 10,
                list: `courts, tribunals, regulatory authorities and law enforcement officers, as required or authorised by law, in
                connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal
                rights;`,

            },
            {
                id: 11,
                list: `third parties to collect and process data, such as Google Analytics (To find out how Google uses data when you
                    use third party websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL
                    Google may use from time to time), Facebook Pixel or other relevant analytics businesses; and`,

            },
            {
                id: 12,
                list: `any other third parties as required or permitted by law, such as where we receive a summons.`,

            },

        ],
        GoogleAnalytics: [
            {
                id: 1,
                Head: `Google Analytics:`,
                description: `We have enabled Google Analytics Advertising Features including Remarketing Features, Advertising
                Reporting Features, Demographics and Interest Reports, Store Visits, and Google Display Network Impression reporting. We
                and third-party vendors use first-party cookies (such as the Google Analytics cookie) or other first-party identifiers, and
                third-party cookies (such as Google advertising cookies) or other third-party identifiers together.`,
                description1: `You can opt-out of Google Analytics Advertising Features including using a Google Analytics Opt-out Browser add-on found`,
                link: `here`,
                description2: `To opt-out of personalised ad delivery on the Google content network, please visit Google’s Ads Preferences Manager `,

                description3: `  or if you wish to opt-out permanently even when all cookies are deleted from your browser you can install their plugin`,

                description4: `.To opt out of interest-based ads on mobile devices, please follow these instructions for your mobile device: On
                 android open the Google Settings app on your device and select “ads” to control the settings. On iOS devices with iOS 6 and
                 above use Apple’s advertising identifier. To learn more about limiting ad tracking using this identifier, visit the settings
                 menu on your device.`,
            },

        ]
    },
    // -------Overseastransfers------------------
    Overseastransfers: {
        Header: [
            {
                id: 1,
                head: `Overseas transfers:`,
                description: `Where we disclose personal data to the third parties listed above, these third parties may store, transfer or access personal
                data outside of the United Kingdom, including India. The level of data protection in countries outside of the United Kingdom
                may be less comprehensive than what is offered in the United Kingdom. Where we transfer your personal data outside of
                the United Kingdom, we will perform those transfers using appropriate safeguards in accordance with the requirements of
                applicable data protection laws and we will protect the transferred personal data in accordance with this Privacy Policy.`,

            }
        ],
        includes: [
            {
                id: 1,
                head: `This includes:`,
                Unorder1: `only transferring your personal data to countries that have been deemed by applicable data protection laws to
                provide an adequate level of protection for personal data; or`,
                Unorder2: `including standard contractual clauses in our agreements with third parties that are overseas.`,

            },
        ],
        ListData: {

            Data1: [

                {
                    id: 1,
                    Head: `Data retention:`,
                    description: `We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including
                for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal
                data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to
                our relationship with you.`,
                    description1: `To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the
                personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which
                we process your personal data and whether we can achieve those purposes through other means, and the applicable legal,
                regulatory, tax, accounting or other requirements.`,

                }
            ],

            Rights: {
                Header: [
                    {
                        id: 1,
                        head: `Your rights and controlling your personal data:`,

                    }
                ],
                list: [
                    {
                        id: 1,
                        Head: `Your choice:`,
                        description: `Please read this Privacy Policy carefully. If you provide personal data to us, you understand we will collect,
                    hold, use and disclose your personal data in accordance with this Privacy Policy. You do not have to provide personal data
                    to us, however, if you do not, it may affect our ability to provide our Services to you and your use of our Services.`,

                    },
                    {
                        id: 2,
                        Head: `Information from third parties:`,
                        description: `If we receive personal data about you from a third party, we will protect it as set out in this
                    Privacy Policy. If you are a third party providing personal data about somebody else, you represent and warrant that you
                    have such person’s consent to provide the personal data to us.`,

                    },
                    {
                        id: 3,
                        Head: `Access, correction, processing and portability:`,
                        description: `You may request details of the personal data that we hold about you and
                    how we process it (commonly known as a “data subject request”). You may also have a right in accordance with applicable
                    data protection law to have your personal data rectified or deleted, to restrict our processing of that information, to object
                    to decisions being made based on automated processing where the decision will produce a legal effect or a similarly
                    significant effect on you, to stop unauthorised transfers of your personal data to a third party and, in some circumstances, to have personal data relating to you transferred to you or another organisation.`,

                    },

                    {
                        id: 4,
                        Head: `Unsubscribe:`,
                        description: `To unsubscribe from our e-mail database or opt-out of communications (including marketing
                        communications), please contact us using the details below or opt-out using the opt-out facilities provided in the
                        communication.`,

                    },
                    {
                        id: 5,
                        Head: `Withdraw consent:`,
                        description: `Where we are relying on consent to process your personal data, you have the right to withdraw your
                    consent at any time. However, this will not affect the lawfulness of any processing carried out before you withdraw your
                    consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise
                    you if this is the case at the time you withdraw your consent.`,

                    },
                    {
                        id: 6,
                        Head: `Complaints:`,
                        description: `If you wish to make a complaint, please contact us using the details below and provide us with full details of
                    the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
                    investigation and the steps we will take to deal with your complaint. You have the right to make a complaint at any time to
                    the Information Commissioner&#39;s Office (ICO), the UK regulator for data protection issues (www.ico.org.uk). We would,
                    however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance`,

                    },

                ]
            }



        }
    },


    // --------------Storage-  Security----------------------
    Storage: [
        {
            id: 1,
            Head: `Storage and security:`,
            description: `We are committed to ensuring that the personal data we collect is secure. In order to prevent unauthorised access or
           disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure personal
           data and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.`,
            description1: `While we are committed to security, we cannot guarantee the security of any information that is transmitted to or by us
           over the Internet. The transmission and exchange of information is carried out at your own risk.`,

        }
    ],
    // --------------------Cookies-------------------
    Cookies: [
        {
            id: 1,
            head: `Cookies:`,
            description: `We may use cookies on our website from time to time. Cookies are text files placed in your computer&#39;s browser to store
            your preferences. For more information about the cookies we use, please see our website`,
            Link: `https://www.deaneinvestment.com/`,
            description1: `Our website may contain links to other party’s websites. We do not have any control over those websites and we are
            not responsible for the protection and privacy of any personal data which you provide whilst visiting those websites.  Those websites are not governed by this Privacy Policy.`,

        }
    ],

    // -------------Amendments-------------------------------
    Amendments: [
        {
            id: 1,
            Head: `Amendments`,
            description: `We may change this Privacy Policy from time to time. We will notify you if we make a significant change to this Privacy
            Policy, by contacting you through the contact details you have provided to us and by publishing an updated version on our
            website.`,
        }
    ],
    // ----------------notices-------------------------
    notices: [
        {
            id: 1,
            head: `For any questions or notices, please contact us at:`,
            Details: `Deane &  Co. Holdings Ltd, a company registered in England and Wales, with company number,`,
            Details2: `13653464. Email: `,
            email: `sales@deaneholdings.com`,
            LastUp: `Last update:`,
            update: ` 14 September 2023`,
            Copy: `©`,
            link: `LegalVision`,
            Name: ` Law UK Ltd`,

        }
    ]






}




export default PrivacyPolicy
