import React from "react";
import { usestate, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import "../Stylesheets/contact.scss";
// import  Logo from '../assets/images/boomerang-logo-contacct.svg'
import Logo from "../assets/images/boomerang-logo-contacct.png";
import logonew from "../assets/images/Logo.png";
import { useState } from "react";
import Swal from "sweetalert2";
import validator from "validator";
import "leaflet/dist/leaflet.css";
import { MapContainer } from "react-leaflet/MapContainer";
import { useMap } from "react-leaflet";
import L from "leaflet";
// import icon from "../assets/location-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";
import icon from "../assets/Location Deane Icon-01.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { TileLayer, Marker } from "react-leaflet";
import axios from "axios";
import { contactus } from "../apidata";
import $ from "jquery";
// import map from "../../src/assets/images/map.webp";
import map from "../../src/assets/images/contactus/panorama.jpg";

import PhoneInput from "react-phone-input-2";

// import pointer from "../../src/assets/images/Deane Investment Map-02.svg";

function Contact() {
  useEffect(() => {
    const observe1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".contact-card").addClass("contactHero");
        }
      });
    });
    // const observe2 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".one").addClass("activate");
    //     }
    //   });
    // });
    observe1.observe(document.querySelector(".contact-card"));
    // observe2.observe(document.querySelector(""));
  }, []);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    select: "",
    message: "",
  });
  const markerRef = useRef();
  const [ShowMap, setShowMap] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [lat, setlong] = useState(51.5007777);
  const [long, setlat] = useState(0.0069023);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const validateName = (e) => {
    var user_name = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(user_name.trim())) {
      setNameError("*Name cannot be empty!");
    } else {
      setNameError("");
    }
  };

  const minZoomLevel = 2;
  const tileLayerOptions = {
    bounds: [
      [-90, -180],
      [90, 180],
    ], // Set the bounds to restrict the visible area
    // maxZoom: 18,
    tileSize: 512,
    zoomOffset: -1,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
    attribution:
      "Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)",
  };
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [176, 87],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  });

  const validatePhone = (value) => {
    setPhoneNumber(value);
    // const { name, value } = e.target;
    setData({
      ...data,
      user_phone: value,
    });
    // if (validator.isEmpty(user_phone)) {
    //   setPhoneError("*Phone Number Cannot be empty!");
    // }
    // else if (
    //   validator.isMobilePhone(user_phone) &&
    //   validator.isLength(value, { min: 10, max: 11 })
    // ) {
    //   setPhoneError("");
    // } else {
    //   setPhoneError("*Enter Valid Phone Number!");
    // }
  };

  const validateEmail = (e) => {
    var user_email = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(user_email)) {
      setEmailError("*Email Cannot be empty!");
    } else if (validator.isEmail(user_email)) {
      setEmailError("");
    } else {
      setEmailError("*Enter valid Email!");
    }
  };

  const validateMessage = (e) => {
    var user_message = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(user_message)) {
      setMessageError("*Message Cannot be empty!");
    } else {
      setMessageError("");
    }
  };
  //
  const sendEmail = (e) => {
    e.preventDefault();

    if (data.user_name == undefined) {
      setNameError("*Name is required!");
    }
    if (data.user_email == undefined) {
      setEmailError("*Email is required!");
    }
    if (data.user_phone == undefined) {
      setPhoneError("*Phone is required!");
    }
    if (data.user_message == undefined) {
      setMessageError("*Message is required!");
      // const div1 = document.getElementById("Message")
      // div1.setAttribute('value','Not Available')
    }

    if (
      data.user_name != undefined &&
      data.user_email != undefined &&
      data.user_phone != undefined &&
      data.user_message != undefined
      // data.user_select != undefined &&
      // data.user_select != "0"
    ) {
      var showLoading = function () {
        Swal.fire({
          html: "Sending...",
          showConfirmButton: false,
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading();
          },

          willClose: () => {
            Swal.hideLoading();
          },
        });
      };

      showLoading();
      let data1 = {};
      data1["name"] = data.user_name;
      data1["email"] = data.user_email;
      data1["phone"] = data.user_phone;
      data1["message"] = data.user_message;
      axios({ method: "post", url: contactus, data: data1 }).then(
        (result) => {
          Swal.fire({
            // icon: 'success',
            // title: 'Message Sent Successfully',
            text: "Thank you for providing your details, we will get in touch shortly!",
          });
          e.target.reset();
        },
        function (error) {
          Swal.fire({
            // icon: 'error',
            // title: 'Message not sent',
            showLoaderOnConfirm: true,
            text: "Oops!, Something went wrong... Please contact on sales@deaneholdings.com",
          });
        }
      );

      e.target.reset();
      setData({
        name: "",
        email: "",
        phone: "",
        select: "",
        message: "",
      });
      setPhoneNumber(null);
    }
  };

  useEffect(() => {
    setShowMap(true);
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="card ">
          <div className="row">
            <div className="contact_wrapper col-md-6">
              <section
                className="mb-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="row">
                  {/* <div className="col-md-1"></div> */}
                  <div className="col-md-12">
                    {/* <h2 className="h1-responsive font-weight-bold text-left my-4">
                      Contact us
                    </h2> */}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <p className="text-left w-responsive mx-auto mb-5 row">
                      <div
                        className="col-lg-12 displaycenter responsive"
                        style={{ width: "100%" }}
                      >
                        <img
                          className="logo-contact"
                          // src={Logo}
                          src={logonew}
                          style={{ width: "130px" }}
                        />
                      </div>
                      <div className="row displaycenter responsive">
                        <div className="col-lg-6 mb-md-0 mb-md-1 mt-4 responsive">
                          <p>
                            Deane Investment,
                            <br />
                            6 Mitre Passage,
                            <br />
                            Greenwich Peninsula,
                            <br />
                            London SE10 0ER,
                            <br />
                            United Kingdom
                          </p>
                        </div>
                        <div
                          className="col-lg-6 mt-4 mt-md-1 col-md-12 mt-4 responsive2"
                          style={{
                            width: "285px",
                            position: "relative",
                            top: "20px",
                          }}
                        >
                          <p>
                            <i></i>
                            <a className="Phoneno" href="tel:+44-203-6436-123">
                              {" "}
                              +44(0)203 355 3603
                            </a>
                          </p>
                          <p>
                            <i></i>{" "}
                            <a
                              className="mailid"
                              href="mailto:sales@deaneholdings.com"
                            >
                              {" "}
                              sales@deaneholdings.com
                            </a>
                          </p>
                          <p>
                            <i></i>
                            <a
                              className="direction"
                              target="_blank"
                              href="https://goo.gl/maps/GEDezUJvfCAzMaz28"
                            >
                              Get Direction
                            </a>
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="row formdetails">
                  <div
                    className="col-lg-5 mb-md-0 mb-5 mt-0"
                    style={{ width: "80%" }}
                  >
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "clamp(18px,1.7vw, 21px)",
                        color: "#0F1E60",
                      }}
                    >
                      Get in touch now to explore lucrative investment
                      opportunities across the UK:
                      {/* Embark on your buy-to-let journey with one of the UK's
                      premier property investment firms. Get in touch now to
                      explore lucrative investment opportunities across the UK.
                      Invest wisely. Invest with Deane Investment. */}
                    </p>
                    <form onSubmit={sendEmail}>
                      <div className="row mt-3">
                        <div className="col-md-12 ">
                          <div className="md-form mb-0">
                            <label for="subject" class="">
                              Name
                            </label>
                            <input
                              type="text"
                              id="user_name"
                              name="user_name"
                              className="form-control"
                              //    onChange={(e)=>setformdata({...formdata,[e.target.name]:e.target.value})}
                              onChange={validateName}
                            />
                            {nameError != "" ? (
                              <p className="text-danger font-weight-bold m-2">
                                {nameError}
                              </p>
                            ) : (
                              ""
                            )}
                            {/* {validation.names && console.log(validation)} */}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div class="md-form mb-0">
                            <label for="name" class="text-left">
                              Email
                            </label>
                            <input
                              type="email"
                              id="user_email"
                              name="user_email"
                              className="form-control"
                              //  onChange={(e)=>setformdata({...formdata,[e.target.name]:e.target.value})}
                              onChange={validateEmail}
                            />
                            {emailError != "" ? (
                              <p className="text-danger font-weight-bold m-2">
                                {emailError}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="md-form mb-0">
                            <label for="phone" class="">
                              Phone
                            </label>
                            {/* <input
                              type="text"
                              id="user_phone"
                              name="user_phone"
                              className="form-control"
                              // onChange={(e)=>setformdata({...formdata,[e.target.name]:e.target.value})}
                              onChange={validatePhone}
                              minlength="10"
                              maxlength="11"
                            /> */}
                            <PhoneInput
                              name="user_phone"
                              country={"gb"}
                              enableSearch={true}
                              placeholder="Eg. 07700 900 000"
                              countryCodeEditable={false}
                              value={phoneNumber}
                              // onChange={(value) => {
                              //   setCreatephoneno(value);
                              // }}
                              // inputClass={"bg-white"}
                              onChange={(value) => validatePhone(value)}
                              style={{
                                paddingleft: "30px",
                              }}
                            />
                            {phoneError != "" ? (
                              <p className="text-danger font-weight-bold m-2">
                                {phoneError}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div className="md-form">
                            <label for="message">Message</label>
                            <textarea
                              type="text"
                              id="user_message"
                              name="user_message"
                              rows="2"
                              className="form-control md-textarea"
                              // onChange={(e)=>setformdata({...formdata,[e.target.name]:e.target.value})}
                              onChange={validateMessage}
                            ></textarea>
                            {messageError ? (
                              <p className="text-danger font-weight-bold m-2">
                                {messageError}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <button
                            className=" contactusbutton"
                            type="submit"
                            onSubmit={sendEmail}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </form>
                    <p
                      className="col-12 mt-3"
                      style={{
                        fontWeight: "600",
                        fontSize: "clamp(18px,1.7vw, 21px)",
                        color: "#0F1E60",
                      }}
                    >
                      *Invest wisely. Invest with Deane Investment.
                    </p>
                    {/* <div className="status"></div> */}
                  </div>
                  {/* <div className='col-md-1 mb-md-0 mb-5'></div> */}
                </div>
              </section>
            </div>
            {ShowMap && (
              <div className="map_view col-md-6" style={{ padding: "30px" }}>
                <div className="map_main">
                  <a
                    href="https://www.google.com/maps/place/6+Mitre+Passage,+London+SE10+0ER,+UK/@51.5007777,0.0069023,179m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47d8a81b9399a949:0xdc32509c5584440e!8m2!3d51.5007777!4d0.0069023!16s%2Fg%2F11b8v4mmsx?entry=ttu"
                    target="_blank"
                  >
                    {/* <img className="pointer" src={pointer} alt="" /> */}
                    <img src={map} alt="" width={"100%"} />
                  </a>

                  {/* <MapContainer
                    center={[lat, long]}
                    zoom={18}
                    scrollWheelZoom={true}
                    minZoom={minZoomLevel}
                    animate={true}
                    // markersData={markersData}
                  >
                    <TileLayer
                      // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      // url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=PSC1Aqdp9CIPw6fWJSgB"
                      // noWrap={true}
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                      url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                      // attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      // url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                      {...tileLayerOptions}
                    />
                    <Marker position={[lat, long]} ref={markerRef} />
                  </MapContainer> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
