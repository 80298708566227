import "../../Stylesheets/About_Us/Our_Team.scss";
import { useState } from "react";
import one from "../../assets/images/PHOTOS-03.webp";
import two from "../../assets/images/PHOTOS-07.webp";
import three from "../../assets/images/PHOTOS-08.webp";
import four from "../../assets/images/PHOTOS-05.webp";
import five from "../../assets/images/PHOTOS-04.webp";
import six from "../../assets/images/PHOTOS-01.jpg";
import seven from "../../assets/images/PHOTOS-02.jpg";
import Eight from "../../assets/images/PHOTOS-06.webp";
import Footer from "../Footer";
import React, { useEffect } from "react";
import $ from "jquery";
import { useInView } from "react-intersection-observer";

function Our_Team() {
  const [team, setteam] = useState([
    {
      img: one,
      name: "Siraj Deane",
      designation: "Managing Director",
    },
    {
      img: two,
      name: "Kasiviswanathan",
      designation: "Office Manager",
    },
    {
      img: three,
      name: "Oleg Tsarev",
      designation: "Project Manager",
    },
    {
      img: four,
      name: "Priyanka",
      designation: "Assistant Operation Manager",
    },
    {
      img: five,
      name: "Eugenia Kovaliova",
      designation: "Marketing Manager",
    },
    {
      img: six,
      name: "Abdul Azzez",
      designation: "Digital Marketing",
    },
    {
      img: seven,
      name: "Yazid Yasin",
      designation: "Product Development & Compliance",
    },
    {
      img: Eight,
      name: "Divya",
      designation: "Project Analyst",
    },
  ]);
  // useEffect(() => {
  //   const observe1 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".out_team_main").addClass("fade_style_ot");
  //       }
  //     });
  //   });
  //   observe1.observe(document.querySelector(".out_team_main"));
  // }, []);

  // const [secondRef, inView] = useInView({
  //   triggerOnce: true,
  //   // enterOptions,
  // });

  // useEffect(() => {
  //   const observe1 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Siraj").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe2 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Kasiviswanathan").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe3 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Oleg").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe4 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Priyanka").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe5 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Eugenia").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe6 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Abdul").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe7 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Yazid").addClass("activate");
  //       }
  //     });
  //   });
  //   const observe8 = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         $(".Divya").addClass("activate");
  //       }
  //     });
  //   });
  //   observe1.observe(document.querySelector(".Siraj"));
  //   observe2.observe(document.querySelector(".Kasiviswanathan"));
  //   observe3.observe(document.querySelector(".Oleg"));
  //   observe4.observe(document.querySelector(".Priyanka"));
  //   observe5.observe(document.querySelector(".Eugenia"));
  //   observe6.observe(document.querySelector(".Abdul"));
  //   observe7.observe(document.querySelector(".Yazid"));
  //   observe8.observe(document.querySelector(".Divya"));
  // }, []);

  return (
    <>
      <div className="out_team_main container-xxl">
        {/* <div className="head-team">Our Team</div> */}
        {/* <div className="container img-cont">
          <div className="row mx-0">
            {team.map((data) => {
              return (
                <div
                  key={data.name}
                  className={`col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 img_card ${data.name}`}
                  // ref={secondRef}
                  // style={{ opacity: "0" }}
                >
                  <div className="img img_block">
                    <img src={data.img} />
                    <div className="text-below">
                      <span className="name">{data.name}</span>
                      <span className="designation">{data.designation}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <Footer></Footer>
    </>
  );
}

export default Our_Team;
