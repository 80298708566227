// const baseurl = "http://3.6.77.151:8003";
// export const baseurl = "http://122.186.126.218:8012";

const baseurl = "https://api.deaneinvestment.com";
// const baseurl = "http://3.6.77.151:9000"

export const propertyget = baseurl + "/property/v1/";

export const contactform = baseurl + "/contactusform/";

export const contactus = baseurl + "/contactusform2/";

