import React, { useEffect, useState } from "react";
import "../Stylesheets/Navbar.scss";
import DehazeIcon from "@mui/icons-material/Dehaze";
import CloseIcon from "@mui/icons-material/Close";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { list } from "../redux/reducer/counterslice";

export default function Navbar(props) {
  const [mobilewidth, setMobilewidth] = useState(false);
  const [mobilemenu, setMobilemenu] = useState(false);
  const [projectpopup, setprojetpopup] = useState(false);
  const [activestate, setactiveState] = useState(0);
  const listselect = useDispatch();

  const handlechange = () => {
    if (window.innerWidth <= 1082) {
      setMobilewidth(true);
    } else {
      setMobilewidth(false);
    }
  };

  const iconhandleclick = () => {
    setMobilemenu(!mobilemenu);
  };

  useEffect(() => {
    handlechange();
    // if (window.location.href.includes("Aboutus")) {
    //   setactiveState(1)
    // }
    // else if (window.location.href.includes("Whatwedo")) {
    //   setactiveState(2)
    // }
    // else if (window.location.href.includes("Projects")) {
    //   setactiveState(3)
    // }
    // else if (window.location.href.includes("Contactus")) {
    //   setactiveState(4)
    // }
  }, []);

  window.addEventListener("resize", handlechange);

  const projectlist = (e) => {
    listselect(list(e.target.innerText));
    window.location.href = "/Projects";
    localStorage.setItem("pageNo", 1);
    localStorage.setItem("laststateid", "");
  };

  return (
    <div
      className={`Nav_bar   ${mobilemenu ? "Background_fadein" : ""} ${
        props.Navcolor && "active"
      }`}
    >
      <div className={`Header mx-auto`}>
        <div className={`left_header`}>
          <div
            className={`img_logo ${props.Navcolor && "active"}`}
            onClick={(e) => {
              window.location.href = "/";
            }}
          ></div>
          <div
            className="brand_name"
            onClick={(e) => {
              window.location.href = "/";
            }}
          >
            {/* TANTUM PROJECTS */}
            {/* DEANE INVESTMENT */}
          </div>
        </div>
        <div className={`Menu_items`}>
          {!mobilewidth && (
            <>
              <span className="pointer">
                <a className={`${props.Navcolor && "active"}`} href="/">
                  HOME
                </a>
              </span>
              <span className="pointer">
                <a className={`${props.Navcolor && "active"}`} href="/Aboutus">
                  ABOUT US
                </a>
              </span>
              <span className="pointer">
                <a className={`${props.Navcolor && "active"}`} href="/Whatwedo">
                  WHAT WE DO
                </a>
              </span>
              <span
                id="projectid"
                className="pointer"
                onMouseOver={() => setprojetpopup(true)}
                onMouseLeave={() => {
                  setprojetpopup(false);
                }}
                style={{ padding: "0px" }}
              >
                <a
                  className={`${props.Navcolor && "active"}`}
                  href="/projectsdashboard"
                >
                  PROJECTS
                </a>
                {/* {projectpopup == true && (
                  <>
                    <ul
                      className={`drop_menu`}
                      onMouseOver={() => setprojetpopup(true)}
                      onMouseLeave={() => {
                        setprojetpopup(false);
                      }}
                    >
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={(e) => projectlist(e)}
                      >
                        <a href="/Projects" style={{ color: "black" }}>
                          AVAILABLE PROJECTS
                        </a>
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={(e) => projectlist(e)}
                      >
                        <a href="/Projects" style={{ color: "black" }}>
                          COMPLETED PROJECTS
                        </a>
                      </li>
                    </ul>
                  </>
                )} */}
              </span>
              {/* <span className="pointer">
                <a className={`${props.Navcolor && "active"}`} href="/">
                  BLOGS
                </a>
              </span> */}
              <span className="pointer">
                <a
                  className={`${props.Navcolor && "active"}`}
                  href="/Contactus"
                >
                  CONTACT
                </a>
              </span>
            </>
          )}
          {mobilewidth && (
            <i onClick={(e) => iconhandleclick(e)}>
              <DehazeIcon></DehazeIcon>
            </i>
          )}
        </div>
        {mobilemenu && (
          <>
            <div className={`Background_fadeout`}>
              <div className={`Mobile_menu`}>
                <div className="iconclass" onClick={(e) => iconhandleclick(e)}>
                  <CloseIcon></CloseIcon>
                </div>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/Aboutus">About us</a>
                  </li>
                  <li>
                    <a href="/Whatwedo">What we do</a>
                  </li>
                  <li
                    onClick={() => {
                      setprojetpopup(!projectpopup);
                    }}
                  >
                    <a href="/projectsdashboard">Projects</a>
                    {/* <i
                      class="bi bi-caret-down-fill"
                      style={{
                        float: "right",
                        color: "black",
                        fontSize: "15px",
                        marginRight: "30px",
                        transform:
                          projectpopup == true
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                        transition: "all 0.3s ease",
                      }}
                    ></i> */}
                  </li>
                  {/* {projectpopup == true && (
                    <>
                      <li style={{ marginLeft: "30px" }}>
                        <a href="/Projects" onClick={(e) => projectlist(e)}>
                          Available Project
                        </a>
                      </li>
                      <li style={{ marginLeft: "30px" }}>
                        <a href="/Projects" onClick={(e) => projectlist(e)}>
                          Completed Project
                        </a>
                      </li>
                    </>
                  )} */}

                  {/* <li>
                    <a href="/">Blog</a>
                  </li> */}
                  <li>
                    <a href="/Contactus">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className={`backgroundshade`}></div> */}
    </div>
  );
}
