import React, { useEffect, useState, useMemo, useRef } from "react";
import "../Stylesheets/Home.scss";
import VideoImg from "../assets/images/lodon.webp";
import imgone from "../assets/images/home/Image 1-Completed project 1.jpg";

// import LogoImg from "../assets/images/large/boomerang-logo.jpg";
import LogoImg from "../assets/images/large/boomerang.jpeg";

import Boomerang1 from "../assets/images/large/signal-boomerang.jpg";
import assets from "../assets/images/small/icon-graph.png";
import assets1 from "../assets/images/small/icon-house.png";
import assets2 from "../assets/images/small/icon-inverst.png";
import Couplesphoto from "../assets/images/large/photo-couples.jpg";
import handshake from "../assets/images/large/photo-hand-shake.jpg";
import handshake1 from "../assets/images/Finance.jpg";
import Skypic from "../assets/images/large/photo-sky.jpg";
import Photodogs from "../assets/images/large/photo-dogs.jpg";
import womenpic from "../assets/images/large/photo-women.jpg";
import Footer from "./Footer";
import $ from "jquery";
import video1 from "../assets/sprite/testing.mp4";
import { useInView } from "react-intersection-observer";
import icon1 from "../assets/icons/Innovation-Icon1_page-0001.jpg";
import icon2 from "../assets/icons/Expertise-Icon2_page-0001.jpg";
import icon3 from "../assets/icons/Success-Icon3_page-0001.jpg";
import icon4 from "../assets/icons/Network-Icon4_page-0001.jpg";

import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";

export default function Home(props) {
  const [MobileView, setMobileView] = useState(false);
  const VideoRef = useRef(null);
  const [BannerView, setBannerView] = useState(false);
  const [videoWidth, setVideoWidth] = useState();

  const handleresize = () => {
    if (window.innerWidth < 571) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  // useEffect(()=>{
  //   //  let videotag=document.getElementById("vid");
  //    if(VideoRef.current){
  //     // console.log(VideoRef.current)
  //     VideoRef.current.play()
  //    }
  // },[VideoRef])

  window.addEventListener("resize", handleresize);
  useEffect(() => {
    handleresize();
    const observe1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".oneImg").addClass("left");
        }
      });
    });
    const observe2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".oneText").addClass("right");
        }
      });
    });
    const observe3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".twoText").addClass("left");
        }
      });
    });
    const observe4 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".twoImg").addClass("right");
        }
      });
    });
    const observe5 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".threeImg").addClass("left");
        }
      });
    });
    const observe6 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".threeText").addClass("right");
        }
      });
    });
    const observe7 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".fourText").addClass("left");
        }
      });
    });
    const observe8 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".fourImg").addClass("right");
        }
      });
    });
    const observe9 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".fiveImg").addClass("left");
        }
      });
    });
    const observe10 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".fiveText").addClass("right");
        }
      });
    });
    const observe11 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".sixText").addClass("left");
        }
      });
    });
    const observe12 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".sixImg").addClass("right");
        }
      });
    });

    const observe13 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".asset_boost").addClass("slideLeft");
        }
      });
    });
    const observe14 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".property_market").addClass("slideTop");
        }
      });
    });
    const observe15 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".safeinvestment").addClass("slideRight");
        }
      });
    });

    observe1.observe(document.querySelector(".oneImg"));
    observe2.observe(document.querySelector(".oneText"));
    observe3.observe(document.querySelector(".twoText"));
    observe4.observe(document.querySelector(".twoImg"));
    observe5.observe(document.querySelector(".threeImg"));
    observe6.observe(document.querySelector(".threeText"));
    observe7.observe(document.querySelector(".fourText"));
    observe8.observe(document.querySelector(".fourImg"));
    observe9.observe(document.querySelector(".fiveImg"));
    observe10.observe(document.querySelector(".fiveText"));
    observe11.observe(document.querySelector(".sixText"));
    observe12.observe(document.querySelector(".sixImg"));
    observe13.observe(document.querySelector(".asset_boost"));
    observe14.observe(document.querySelector(".property_market"));
    observe15.observe(document.querySelector(".safeinvestment"));
  }, []);

  // const enterOptions{() => {

  // }}

  // const handRest=()=>{
  //     setVideoWidth((String(window.innerWidth)+"px"))
  // }

  // window.addEventListener("resize",handRest)

  const [secondRef, inView] = useInView({
    triggerOnce: true,
    // enterOptions,
  });
  const [cardOneRef, cardOneInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    // enterOptions,
  });
  const [buyToletInvestmentRef, buyToletInvestmentInView] = useInView({
    triggerOnce: true,
    // enterOptions,
    threshold: 0.2,
  });
  const [h1Ref, h1InView] = useInView({
    triggerOnce: true,
    // enterOptions,
  });
  const [imagesRef, imagesInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    // enterOptions,
  });
  const [textRef, textInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    // enterOptions,
  });

  const TimeUpdate = (e) => {
    // console.log(e.target)
    // console.log(e.target.duration, e.target.currentTime)

    if (e.target.currentTime > e.target.duration - 5) {
      setBannerView(true);
      // .off('timeupdate'); // <--
    }
  };

  const myCallback = (e) => {
    //   console.log(e)
  };

  return (
    <div className={`Home_wrapper`}>
      <div className="Home_outer_wrpper">
        <div className={`Home_inner_warpper`}>
          <video
            id="vid"
            muted="muted"
            playsInline
            src={video1}
            controls={false}
            width={"100%"}
            height={"100%"}
            autoPlay
            ref={VideoRef}
            onEnded={(e) => myCallback(e)}
            onTimeUpdate={(e) => TimeUpdate(e)}
          ></video>
          {BannerView && (
            <div className={`Home_banner_textcontainer`}>
              <h1 style={{ fontFamily: "'Roboto Slab', serif !important;" }}>
                Throw Your First Boomerang Today, and Prepare to Catch the
                Profits
              </h1>
              <p>
                Exclusive property deals not available through traditional
                means.{" "}
                <span className="unlock">make a good return and quickly</span>{" "}
                take out most of your initial investment.
              </p>
            </div>
          )}
        </div>
      </div>

      <div
        className={`container-xl Home_second_container ${
          inView ? "animate" : ""
        }`}
        ref={secondRef}
        style={{ opacity: "0" }}
      >
        <div className="row">
          <div className="col-lg-8 col-md-7 content">
            {/* <h3>Key Proposition</h3> */}
            <div className="card">
              <div className="card-body">
                <p style={{ textAlign: "justify" }}>
                  With Deane Investment you choose whether to invest in a
                  property needing refurbishment to make a profit or to invest
                  in a buy-to-let property.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Of course you can do both by keeping your refurbished property
                  to let out.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Our expert help saves your time by guiding your property
                  investment and providing the advice and services you need to
                  profit from this exciting and profitable asset class.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We can help you find suitable properties for development,
                  refurbishment or to maximize your buy-to-let income. Our
                  strategy is for you to increase the value of your assets
                  without tying up large amounts of capital for the long term,
                  leaving you free to enjoy your profits and take care of other
                  things.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="video_img">
              <img src={imgone} className="video_src"></img>
              {/* <p>WATCH OUR SHORT VIDEO</p> */}
              <img src={LogoImg} className="logo_src"></img>
            </div>
            {/* <img src={VideoImg} srcset="src/assets/images/small//boomerang-logo.jpg 360w, src/assets/images/medium//boomerang-logo.jpg 768w, src/assets/images/large//boomerang-logo.jpg 1366w"/> */}
          </div>
        </div>
      </div>

      {/* <div className={`Home_second_container`} ref={myRef}></div> */}

      <div className={`Home_fourth_component container-xxl`}>
        <div className={`Header`}>
          <h1
            className={`h1 ${h1InView ? "animate" : ""}`}
            ref={h1Ref}
            style={{ fontSize: "3rem", opacity: "0", marginBottom: "20px" }}
          >
            WHAT MAKES BOOMERANG UNIQUE?
          </h1>
        </div>
        <div className={`Gridview`}>
          {/* Exclusive projects */}
          <div className="row gap">
            <div className="row gridimg observer_content">
              <img
                className={`col-lg-3 col-sm-4 scale active oneImg`}
                src={icon1}
                ref={imagesRef}
                style={{ opacity: "0" }}
              ></img>
              <div
                className={`col-lg-5 col-sm-8 inner_content  oneText`}
                ref={imagesRef}
                style={{ opacity: "0" }}
              >
                <p className="Header">Exclusive projects</p>
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  We identify the best development opportunities, often before
                  they are publicly available, We provide our clients with the
                  greatest possible return on their investment.
                </p>
              </div>
            </div>
          </div>

          {/* Finance */}
          {!MobileView && (
            <div className="row p-2 gap observer_content1">
              <div
                className={`col-lg-5 col-sm-8 inner_content twoText `}
                ref={imagesRef}
                style={{ opacity: "0" }}
              >
                <p className="Header">Finance</p>
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  Deane Investment partners with a selection of established UK
                  financial services firms to smooth the path for our clients to
                  financing. We do not charge an arrangement fee for this,
                  saving our clients some 1.5% of the property value.
                </p>
              </div>
              {/* <div> */}
              <img
                className={`col-lg-3 col-sm-4 scale gridimg twoImg`}
                src={icon4}
                width={"100%"}
                height={"100%"}
                ref={imagesRef}
                style={{ opacity: "0" }}
                alt=""
              ></img>
              {/* </div> */}
            </div>
          )}
          {MobileView && (
            <div className="row p-2 gap observer_content1">
              {/* <img src={Couplesphoto}></img> */}
              <img
                className={`col-lg-3 col-sm-4 scale gridimg `}
                // ref={imagesRef}
                // style={{ opacity: "0" }}
                src={icon4}
                width={"100%"}
                height={"100%"}
                alt=""
              />
              <div
                className={`col-lg-5 col-sm-8 inner_content  `}
                // ref={imagesRef}
                // style={{ opacity: "0" }}
              >
                <p className="Header">Finance</p>
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  Deane Investment partners with a selection of established UK
                  financial services firms to smooth the path for our clients to
                  financing. We do not charge an arrangement fee for this,
                  saving our clients some 1.5% of the property value.
                </p>
              </div>
              {/* <div> */}
              {/* </div> */}
            </div>
          )}

          {/* Unlock your deposit  */}
          <div className="row p-2 gap observer_content2">
            {/* <div> */}
            <img
              className={`col-lg-3 col-sm-4 scale gridimg threeImg`}
              ref={imagesRef}
              style={{ opacity: "0" }}
              src={icon3}
            />
            {/* </div> */}
            <div
              className={`col-lg-5 col-sm-8 inner_content threeText`}
              ref={imagesRef}
              // style={{ opacity: "0" }}
            >
              <p className="Header">Unlock your deposit</p>
              <p style={{ textAlign: "justify", fontSize: "16px" }}>
                Boomerang is a distinctive business model, based on our
                extensive expertise in the property industry. If you want to
                retain your property, you can release 50-100% of your initial
                investment after works are completed. The reason: your
                property's value has increased significantly.
              </p>
            </div>
          </div>

          {/* Portfolio expansion */}
          {!MobileView && (
            <div className="row p-2 gap observer_content3">
              <div
                className={`col-lg-5 col-sm-8 inner_content fourText`}
                ref={textRef}
                style={{ opacity: "0" }}
              >
                <p className="Header">Portfolio expansion</p>
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  If you want to realise your gain, we ensure our clients are
                  able to reinvest their initial outlay into another property
                  promptly. Fast turnaround times mean, effective portfolio
                  expansion and, naturally, increased profits.
                </p>
              </div>
              {/* <div> */}
              <img
                className={`col-lg-3 col-sm-4 scale gridimg fourImg`}
                ref={textRef}
                style={{ opacity: "0" }}
                src={icon2}
              ></img>
              {/* </div> */}
            </div>
          )}
          {MobileView && (
            <div className="row p-2 gap observer_content3">
              <img
                className={`col-lg-3 col-sm-4 scale gridimg `}
                ref={textRef}
                // style={{ opacity: "0" }}
                src={icon2}
              ></img>
              <div
                className={`col-lg-5 col-sm-8 inner_content `}
                ref={textRef}
                // style={{ opacity: "0" }}
              >
                <p className="Header">Portfolio expansion</p>
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  If you want to realise your gain, we ensure our clients are
                  able to reinvest their initial outlay into another property
                  promptly. Fast turnaround times mean, effective portfolio
                  expansion and, naturally, increased profits.
                </p>
              </div>
              {/* <div> */}
              {/* </div> */}
            </div>
          )}

          {/* Increased rental yields */}
          <div className="row p-2 gap observer_content4">
            {/* <div> */}
            <img
              className={`col-lg-3 col-sm-4 scale gridimg fiveImg`}
              ref={textRef}
              style={{ opacity: "0" }}
              src={icon3}
            ></img>
            {/* </div> */}
            <div
              className={`col-lg-5 col-sm-8 inner_content fiveText`}
              ref={textRef}
              style={{ opacity: "0" }}
            >
              <p className="Header">Increased rental yields</p>
              <p style={{ textAlign: "justify", fontSize: "16px" }}>
                If you keep your property, we can achieve up to 60% higher
                rental returns on your investment too (compared to the average).
              </p>
              {/* <img src={Couplesphoto}></img> */}
            </div>
          </div>

          {/* earning trust */}
          {!MobileView && (
            <div className="row p-2 gap observer_content5  ">
              <div
                className={`col-lg-5 col-sm-8 inner_content sixText`}
                ref={textRef}
                // style={{ opacity: "0" }}
              >
                <p className="Header">Earning trust</p>
                <p style={{ textAlign: "justify" }}>
                  Deane Investment wants to develop long-term working
                  partnerships with our clients, We want to, guide and nurture
                  the growth of your portfolio in a strong, ongoing business
                  relationship.
                </p>
              </div>
              {/* <div> */}
              <img
                className={`col-lg-3 col-sm-4 scale gridimg sixImg`}
                ref={textRef}
                // style={{ opacity: "0" }}
                src={icon4}
              ></img>
              {/* </div> */}
            </div>
          )}
          {MobileView && (
            <div className="row p-2 gap observer_content5">
              <img
                className={`col-lg-3 col-sm-4 scale gridimg sixImg`}
                ref={textRef}
                // style={{ opacity: "0" }}
                src={icon4}
              ></img>
              <div
                className={`col-lg-5 col-sm-8 inner_content sixText`}
                ref={textRef}
                // style={{ opacity: "0" }}
              >
                <p className="Header">Earning trust</p>
                <p style={{ textAlign: "justify" }}>
                  Deane Investment wants to develop long-term working
                  partnerships with our clients, We want to, guide and nurture
                  the growth of your portfolio in a strong, ongoing business
                  relationship.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`Home_third_component`}>
        <img src={Boomerang1} className="boomerang"></img>
        <div
          className={`buytoletinvestment ${
            buyToletInvestmentInView ? "animate" : ""
          }`}
          ref={buyToletInvestmentRef}
          style={{ opacity: "0" }}
        >
          <h1>THE ATTRACTION OF BUY-TO-LET INVESTMENT</h1>
          <div className="row three_cards">
            <div
              className="col-xl-2 col-md-3 col-xl-3 asset_boost"
              // className={`col-xl-2 col-md-3 col-xl-3 asset_boost ${
              //   cardOneInView ? "animate" : ""
              // }`}
              ref={cardOneRef}
              // style={{ opacity: "0" }}
            >
              {/* <img src={assets}></img> */}
              <LooksOneIcon />

              <h2
                className="Header"
                style={{ fontSize: "20px", color: "#011e60" }}
              >
                Asset boost
              </h2>
              <p style={{ textAlign: "left", fontSize: "16px" }}>
                Owning a buy-to-let property can enable you to generate a
                regular monthly income and benefit from an increase in the value
                of your asset over time too.
              </p>
            </div>
            <div
              className="col-xl-2 col-md-3 col-xl-3 property_market"
              // className={`col-xl-2 col-md-3 col-xl-3 property_market ${
              //   cardOneInView ? "animate" : ""
              // }`}
              ref={cardOneRef}
              // style={{ opacity: "0" }}
            >
              {/* <img src={assets1}></img> */}
              <LooksTwoIcon />

              <h2
                className="Header"
                style={{ fontSize: "20px", color: "#011e60" }}
              >
                UK property market
              </h2>
              <p style={{ textAlign: "left", fontSize: "16px" }}>
                The UK lettings market is currently very strong, as it has been
                over the long term sustained by a lack of affordable properties
                to purchase (both residential and commercial) and strict
                mortgage underwriting criteria.
              </p>
            </div>
            <div
              className="col-xl-2 col-md-3 col-xl-3 safeinvestment"
              // className={`col-xl-2 col-md-3 col-xl-3 safeinvestment ${
              //   cardOneInView ? "animate" : ""
              // }`}
              ref={cardOneRef}
              // style={{ opacity: "0" }}
            >
              {/* <img src={assets2}></img> */}
              <Looks3Icon />

              <h2
                className="Header"
                style={{ fontSize: "20px", color: "#011e60" }}
              >
                Safe investment
              </h2>
              <p style={{ textAlign: "left", fontSize: "16px" }}>
                Both companies and individuals investing in buy-to-let property
                know this is a stable, straightforward way to achieve consistent
                long-term returns on their investment.
              </p>
            </div>
          </div>
        </div>
        <img src={Boomerang1} style={{ marginBottom: "20px" }}></img>
      </div>
      <Footer></Footer>
    </div>
  );
}
