import { useEffect, useState } from "react";
import "../../Stylesheets/projects/project_card.scss";
import CircularIndeterminatecard from "./loader_card";
import noimg from "../../assets/noimage.png";
import logo from "../../assets/images/contacts_dean.png";
import test1 from "../../assets/images/test1.jpeg";
import test2 from "../../assets/images/test2.jpeg";
import test3 from "../../assets/images/brand.jpeg";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import { propertyget } from "../../apidata";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { propertyid } from "../../redux/reducer/counterslice";
import $ from "jquery";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import nodata from "../../assets/images/no-data-found.png";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Footer from "../Footer";

function Project_Card(props) {
  const [getdata, setGetData] = useState([]);
  const propertypage = useNavigate();
  const dispatchid = useDispatch();
  const [datacount, setdatacount] = useState(0);
  // let pageno=localStorage.setItem("pageNo",1)
  const [page, setPage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  // console.log(datacount / 10);
  const agentform = useNavigate();

  // console.log(country)
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setloader] = useState(true);
  const propertydata = useSelector((state) => state.counter.list);
  const [SeemoreIndex, setSeemoreIndex] = useState();
  const [id, setid] = useState("");

  console.log(propertydata, "pdpd");

  let index1 = 0;
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("laststateid") == null ||
      localStorage.getItem("laststateid") == ""
    ) {
      $("html, body").animate({ scrollTop: "0px" }, 100);
      const interval = setInterval(() => {
        let div = document.getElementById("Whatsappicon");
        if (div) {
          div.style.display = "none";
          clearInterval(interval);
        }
      }, 10);
    } else {
      scroll();
    }
  }, []);
  const scroll = () => {
    setTimeout(() => {
      var myElement = document.getElementById(
        localStorage.getItem("laststateid")
      );
      console.log(myElement);
      myElement.scrollIntoView({ block: "center" });
    }, 700);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  // window.screenTop
  const [currentPage, setCurrentPage] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); // Track whether local storage value has been loaded

  // Retrieve currentPage from localStorage on component mount
  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    if (storedPage) {
      setCurrentPage(parseInt(storedPage));
    }
    setIsLoaded(true); // Mark local storage value as loaded
  }, []);

  // Update localStorage when currentPage changes
  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  // useEffect(() => {
  //   setloader(true);

  //   axios({
  //     method: "get",
  //     url: propertyget,
  //     headers: { "content-type": "application/json" },
  //     params: {
  //       status:
  //         propertydata == "Completed projects"
  //           ? 0
  //           : propertydata == "development"
  //           ? 1
  //           : propertydata == "Fully refurbished"
  //           ? 2
  //           : propertydata == "requiring refurbishment"
  //           ? 3
  //           : "",
  //       search: searchQuery,
  //       page: currentPage, // Use currentPage directly from state
  //       page_size: pagesize,
  //       is_active: "True",
  //     },
  //   })
  //     .then((res) => {
  //       if (propertydata == "Completed projects") {
  //         setdatacount(res.data.data_count.completed);
  //         // console.log(res.data.data_count.completed, "com");
  //       } else if (propertydata == "development") {
  //         setdatacount(res.data.data_count.dev_project);
  //         // console.log(res.data.data_count.dev_project, "pfd");
  //       } else if (propertydata == "Fully refurbished") {
  //         setdatacount(res.data.data_count.fully_refurbed);
  //         // console.log(res.data.data_count.fully_refurbed, "frp");
  //       } else if (propertydata == "requiring refurbishment") {
  //         setdatacount(res.data.data_count.require_refurbishment);
  //         // console.log(res.data.data_count.require_refurbishment, "prr");
  //       }

  //       setGetData(res.data.data);
  //       console.log(res.data.data, "setGetData1", res.data.data_count.is_sold);
  //       // setTimeout(() => {
  //       setloader(false);
  //       // }, 2000);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setloader(false);
  //     });
  // }, [currentPage, searchQuery, page, pagesize, propertydata]);

  // Only fetch data if local storage value has been loaded
  useEffect(() => {
    if (isLoaded) {
      setloader(true);

      axios({
        method: "get",
        url: propertyget,
        headers: { "content-type": "application/json" },
        params: {
          status:
            propertydata == "Completed projects"
              ? 0
              : propertydata == "development"
              ? 1
              : propertydata == "Fully refurbished"
              ? 2
              : propertydata == "requiring refurbishment"
              ? 3
              : "",
          search: searchQuery,
          page: currentPage,
          page_size: pagesize,
          is_active: "True",
        },
      })
        .then((res) => {
          if (propertydata == "Completed projects") {
            setdatacount(res.data.data_count.completed);
          } else if (propertydata == "development") {
            setdatacount(res.data.data_count.dev_project);
          } else if (propertydata == "Fully refurbished") {
            setdatacount(res.data.data_count.fully_refurbed);
          } else if (propertydata == "requiring refurbishment") {
            setdatacount(res.data.data_count.require_refurbishment);
          }

          setGetData(res.data.data);
          setloader(false);
        })
        .catch((error) => {
          console.log(error);
          setloader(false);
        });
    }
  }, [isLoaded, currentPage, searchQuery, pagesize, propertydata]);

  const caroselenter = () => {
    $(".prev").css({ display: "block" });
    $(".next").css({ display: "block" });
  };

  const caroselleave = () => {
    $(".prev").css({ display: "none" });
    $(".next").css({ display: "none" });
  };

  // const [Description, setDescription] = useState("");
  // useEffect(() => {
  //   console.log(Description);
  // }, [Description]);

  return (
    <>
      <div
        className={`container-xxl card_parent ${props.viewItem && "active"}`}
      >
        {!props.viewItem && (
          <div className="row">
            <div
              className="col-md-12"
              style={{
                textAlign: "end",
                marginTop: "-90px",
                fontSize: "40px",
                // fontFamily: "Roboto Slab", serif
                marginBottom: "100px",
              }}
            >
              <h1 className="property_header" id="property_headerid">
                {/* {propertydata != "Completed projects"
                  ? "Available Projects"
                  : "Completed Projects"} */}

                {propertydata}
              </h1>
            </div>

            <div className="col-md-6">
              <div
                class="input-group rounded"
                style={{ width: "90%", height: "40px", margin: "25px" }}
              >
                <input
                  type="search"
                  class="form-control rounded"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  onInput={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  label="Search"
                  placeholder="Search Property"
                />
                <span
                  class="input-group-text border-0 bg-transparent"
                  id="search-addon"
                >
                  <i
                    class="bi bi-search"
                    style={{
                      color: "rgb(0, 208, 241)",
                      width: "20px",
                      height: "20px",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        )}

        {getdata && getdata.length > 0 ? (
          propertydata != "Completed projects" ? (
            getdata.map((ele, index) => {
              return (
                <>
                  <div
                    id={ele.id}
                    class={`card data${index}`}
                    onClick={(e) => {
                      // e.stopPropagation();
                      // e.preventDefault();
                      console.log(e);
                      let target = e.target;
                      while (
                        target &&
                        !target.classList.contains(`data${index}`)
                      ) {
                        target = target.parentElement;
                      }

                      // If a parent with class "parent" was found, target will be that element
                      if (target && target.classList.contains(`data${index}`)) {
                        // You've found the top-most parent div
                        console.log(target.id); // This will log the id of the top-most parent div
                        localStorage.setItem("laststateid", target.id);
                      }
                    }}
                    style={{
                      marginBottom: "40px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      border: "none",
                      width: "100%",
                    }}
                  >
                    <div class="row">
                      <div className="col-md-6 p-0">
                        <div
                          class="col-12 "
                          style={{ paddingRight: "12px", paddingLeft: "12px" }}
                        >
                          <div
                            id={`${"carouselExampleControls"}` + index}
                            class="carousel "
                            data-interval="false"
                            style={{ height: "100%" }}
                          >
                            <div
                              class="carousel-inner"
                              style={{ height: "100%", cursor: "pointer" }}
                            >
                              {screenSize.dynamicWidth > 800 ? (
                                <>
                                  {ele.media_files.map((element, indexele) => {
                                    if (ele.media_files.length >= 3) {
                                      if (
                                        indexele <=
                                        ele.media_files.length / 2
                                      ) {
                                        index1 = index1 + 1;
                                        return (
                                          <>
                                            <div
                                              className={`carousel-item ${
                                                indexele == 0 ? " active" : ""
                                              }`}
                                            >
                                              <div
                                                className="row"
                                                style={{
                                                  backgroundColor: "#F4F4F5",
                                                  borderRadius: "10px",
                                                }}
                                              >
                                                {" "}
                                                <div className="offer_tag_ba">
                                                  {ele.dropdown_for_banners}
                                                </div>
                                                <div
                                                  className="col-6 "
                                                  style={{
                                                    height: "230px",
                                                    borderRadius:
                                                      "10px 0px 0px 0px",
                                                  }}
                                                >
                                                  {/* {ele.offer_display &&
                                                    ele.offer_display !=
                                                      null && (
                                                      <div className="offer_tag">
                                                        {ele.offer_display}
                                                      </div>
                                                    )} */}
                                                  <img
                                                    id={indexele}
                                                    src={
                                                      ele.media_files[
                                                        index1 - 1
                                                      ]
                                                    }
                                                    class="d-block w-100"
                                                    alt="..."
                                                    style={{
                                                      borderRadius:
                                                        "10px 0px 0px 0px",
                                                      height: "100%",
                                                    }}
                                                    onClick={(e) => {
                                                      propertypage("/Property");

                                                      window.scrollTo(0, 0);
                                                      dispatchid(
                                                        propertyid(ele.id)
                                                      );
                                                    }}
                                                  ></img>
                                                </div>
                                                <div
                                                  className="col-6 p-0"
                                                  style={{ height: "230px" }}
                                                >
                                                  <img
                                                    src={
                                                      ele.media_files[
                                                        index1 + 1
                                                      ]
                                                    }
                                                    class="d-block w-100"
                                                    alt="..."
                                                    style={{
                                                      marginBottom: "16px",
                                                      borderRadius: "2px",
                                                      height: "100%",
                                                    }}
                                                    onClick={(e) => {
                                                      propertypage("/Property");
                                                      window.scrollTo(0, 0);
                                                      dispatchid(
                                                        propertyid(ele.id)
                                                      );
                                                    }}
                                                  ></img>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                      index1 = 0;
                                    } else if (ele.media_files.length == 2) {
                                      return (
                                        <>
                                          <div
                                            className={`carousel-item ${
                                              indexele == 0 ? " active" : ""
                                            }`}
                                          >
                                            <div
                                              className="row"
                                              style={{
                                                backgroundColor: "#F4F4F5",
                                                borderRadius: "10px",
                                              }}
                                            >
                                              <div
                                                className="col-6 "
                                                style={{
                                                  height: "230px",
                                                  borderRadius:
                                                    "10px 0px 0px 0px",
                                                }}
                                              >
                                                {/* {ele.offer_display &&
                                                  ele.offer_display != null && (
                                                    <div className="offer_tag">
                                                      {ele.offer_display}
                                                    </div>
                                                  )} */}

                                                <img
                                                  // id={indexele}
                                                  src={ele.media_files[index1]}
                                                  class="d-block w-100"
                                                  alt="..."
                                                  style={{
                                                    borderRadius:
                                                      "10px 0px 0px 0px",
                                                    height: "100%",
                                                  }}
                                                  onClick={(e) => {
                                                    propertypage("/Property");
                                                    window.scrollTo(0, 0);
                                                    dispatchid(
                                                      propertyid(ele.id)
                                                    );
                                                  }}
                                                ></img>
                                              </div>
                                              <div
                                                className="col-6 p-0"
                                                style={{ height: "230px" }}
                                              >
                                                <img
                                                  src={
                                                    ele.media_files[index1 + 1]
                                                  }
                                                  class="d-block w-100"
                                                  alt="..."
                                                  style={{
                                                    marginBottom: "16px",
                                                    borderRadius: "2px",
                                                    height: "100%",
                                                  }}
                                                  onClick={(e) => {
                                                    propertypage("/Property");
                                                    window.scrollTo(0, 0);
                                                    dispatchid(
                                                      propertyid(ele.id)
                                                    );
                                                  }}
                                                ></img>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <div
                                            className={`carousel-item ${
                                              indexele == 0 ? " active" : ""
                                            }`}
                                          >
                                            <div className="row">
                                              <div
                                                className="col-12"
                                                style={{
                                                  height: "230px",
                                                  // padding: 0,
                                                }}
                                              >
                                                {/* {ele.offer_display &&
                                                  ele.offer_display != null && (
                                                    <div className="offer_tag">
                                                      {ele.offer_display}
                                                    </div>
                                                  )} */}

                                                <img
                                                  id={indexele}
                                                  src={element}
                                                  class="d-block w-100"
                                                  alt="..."
                                                  style={{
                                                    borderRadius:
                                                      "10px 0px 0px 0px",
                                                    height: "100%",
                                                  }}
                                                  onClick={(e) => {
                                                    propertypage("/Property");
                                                    window.scrollTo(0, 0);
                                                    dispatchid(
                                                      propertyid(ele.id)
                                                    );
                                                  }}
                                                ></img>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                                </>
                              ) : (
                                <>
                                  {ele.media_files.map((element, indexele) => {
                                    if (
                                      indexele <=
                                      ele.media_files.length / 2
                                    ) {
                                      index1 = index1 + 1;
                                      return (
                                        <>
                                          <div
                                            className={`carousel-item ${
                                              indexele == 0 ? " active" : ""
                                            }`}
                                          >
                                            <div className="row">
                                              <div
                                                className="col-12"
                                                style={{
                                                  height: "230px",
                                                  padding: 0,
                                                }}
                                              >
                                                {/* {ele.offer_display &&
                                                  ele.offer_display != null && (
                                                    <div className="offer_tag">
                                                      {ele.offer_display}
                                                    </div>
                                                  )} */}
                                                <div className="offer_tag_ba">
                                                  {ele.dropdown_for_banners}
                                                </div>

                                                <img
                                                  id={indexele}
                                                  src={element}
                                                  class="d-block w-100"
                                                  alt="..."
                                                  style={{
                                                    borderRadius: "2px",
                                                    height: "100%",
                                                  }}
                                                  onClick={(e) => {
                                                    propertypage("/Property");
                                                    window.scrollTo(0, 0);
                                                    dispatchid(
                                                      propertyid(ele.id)
                                                    );
                                                  }}
                                                ></img>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                    index1 = 0;
                                  })}
                                </>
                              )}
                            </div>
                            <a
                              class="carousel-control-prev prev"
                              href={`${"#carouselExampleControls"}` + index}
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                class="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a
                              class="carousel-control-next next"
                              href={`${"#carouselExampleControls"}` + index}
                              role="button"
                              data-slide="next"
                            >
                              <span
                                class="carousel-control-next-icon "
                                aria-hidden="true"
                              ></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        </div>

                        <div
                          className="col-12"
                          style={{
                            paddingRight: "12px",
                            paddingLeft: "12px",
                            height: "calc(100% - 230px)",
                          }}
                          onClick={(e) => {
                            propertypage("/Property");
                            window.scrollTo(0, 0);
                            dispatchid(propertyid(ele.id));
                          }}
                        >
                          <div className="property_price" id={ele.id}>
                            <p className="price">
                              £
                              {ele.price != ""
                                ? String(ele.price).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                : ""}
                            </p>
                            {/* <p
                              className="offer"
                              style={{ fontSize: "15px", color: "#545466" }}
                            >
                              Offers in Excess of
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6 p-0"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          class="col-12"
                          onClick={(e) => {
                            propertypage("/Property");
                            window.scrollTo(0, 0);
                            dispatchid(propertyid(ele.id));
                          }}
                        >
                          <div className="main_content">
                            <p
                              style={{
                                marginBottom: "3px",
                                fontWeight: "700",
                                color: "#262637",
                              }}
                            >
                              {ele.property_address}
                            </p>
                            <div className="count">
                              <p style={{ marginRight: "10px" }}>
                                {ele.property_type}
                              </p>
                              {ele.bathrooms !== 0 && (
                                <>
                                  <i
                                    class="fas fa-bed"
                                    style={{
                                      marginRight: "10px",
                                      fontFamily: "Font Awesome 6 Free",
                                    }}
                                  ></i>
                                  <span style={{ marginRight: "10px" }}>
                                    {ele.bedrooms}
                                  </span>
                                </>
                              )}
                              {ele.bedrooms !== 0 && (
                                <>
                                  <i
                                    class="fas fa-bath"
                                    style={{ marginRight: "10px" }}
                                  ></i>
                                  <span style={{ marginRight: "10px" }}>
                                    {ele.bathrooms}
                                  </span>
                                </>
                              )}
                            </div>
                            <p
                              style={{
                                marginBottom:
                                  ele.description.length > 100
                                    ? "22px"
                                    : "76px",
                                color: "#575F71",
                                cursor: "pointer",
                              }}
                            >
                              {/* {ele.description && ele.description.length > 200
                                ? `${ele.description.substring(
                                    0,
                                    200
                                  )}......See More`
                                : `${ele.description}`} */}
                              <div
                                className="text_box"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    ele.description &&
                                    ele.description.length > 200
                                      ? `${ele.description.substring(
                                          0,
                                          200
                                        )}<p class='seemore'>......See More</p>`
                                      : `${ele.description}`,
                                }}
                              ></div>
                            </p>
                            {/* <p
                          style={{
                            fontSize: "15px",
                            color: "#0c8852",
                            fontWeight: "600",
                          }}
                        >
                          Added on{" "}
                          {String(ele.created_date)
                            .substring(0, 10)
                            .replaceAll("-", "/")}
                        </p> */}
                          </div>
                        </div>

                        <div
                          className="col-12"
                          onClick={(e) => {
                            propertypage("/Property");
                            window.scrollTo(0, 0);
                            dispatchid(propertyid(ele.id));
                          }}
                        >
                          <div className="contact">
                            <div className="content">
                              {/* <img src={test3} style={{ padding: "10px" }}></img>
                          <div className="contect_details">
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: "12px",
                              }}
                            >
                              {ele.agent_phone_number}
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: "12px",
                              }}
                            >
                              Local call rate
                            </p>
                          </div> */}
                              <div
                                className="footer_contact"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatchid(propertyid(ele.id));

                                  agentform("/AgentForm");
                                }}
                              >
                                <i
                                  class="bi bi-envelope"
                                  style={{ fontSize: "23px" }}
                                ></i>
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: "16px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Request more details
                                </p>
                              </div>
                            </div>
                            {/* <div className="save">
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "23px" }}
                          ></i>
                          <p style={{ padding: 0, margin: 0 }}>Save</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              {getdata.map((ele, index) => {
                return (
                  <div
                    class={`row  data${index}`}
                    id={ele.id}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      // padding: "0px 0px 0px 108px",
                      margin: "auto",
                      cursor: "default",
                      // boxShadow: "10px 0px 0px 10px",
                      alignContent: "center",
                      marginBottom: "20px",
                      borderRadius: "10px",
                      padding: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // gap: "10px",
                    }}
                  >
                    {/* <div className="main_content1">
                      <p
                        style={{
                          marginBottom: "3px",
                          fontWeight: "700",
                          color: "#262637",
                          fontSize: "25px",
                        }}
                      >
                        {ele.property_address}
                      </p>
                      <div className="count1">
                        <p style={{ marginRight: "10px" }}>
                          {ele.property_type}
                        </p>
                        <i
                          class="fas fa-bed"
                          style={{ marginRight: "10px" }}
                        ></i>
                        <span style={{ marginRight: "10px" }}>
                          {ele.bedrooms}
                        </span>
                        <i
                          class="fas fa-bath"
                          style={{ marginRight: "10px" }}
                        ></i>
                        <span style={{ marginRight: "10px" }}>
                          {ele.bathrooms}
                        </span>
                      </div>
                    </div> */}
                    <div className="col-md-6 p-0">
                      <div className="new_card">
                        <div className="row">
                          <div
                            class="col-6 "
                            style={{
                              paddingRight: "12px",
                              paddingLeft: "12px",
                              cursor: "default",
                            }}
                          >
                            <div
                              id={`${"carouselExampleControls1"}` + index}
                              class="carousel "
                              data-interval="false"
                              style={{ height: "100%", cursor: "default" }}
                            >
                              <div
                                class="carousel-inner"
                                style={{
                                  height: "100%",
                                  borderRadius: "10px 10px 10px 10px",
                                }}
                              >
                                <>
                                  {ele.before.length > 0 ? (
                                    ele.before.map((element, indexele) => {
                                      return (
                                        <>
                                          {/* <h2>Before</h2> */}

                                          <div
                                            className={`carousel-item ${
                                              indexele == 0 ? " active" : ""
                                            }`}
                                          >
                                            <div className="row">
                                              <div
                                                className="col-12"
                                                style={{
                                                  height: "230px",
                                                  padding: 0,
                                                }}
                                              >
                                                <div className="offer_tag_ba">
                                                  Before
                                                </div>

                                                <img
                                                  id={indexele}
                                                  src={element}
                                                  class="d-block w-100"
                                                  alt="..."
                                                  style={{
                                                    height: "100%",
                                                    cursor: "default",
                                                  }}
                                                  // onClick={(e) => {
                                                  //   propertypage("/Property");
                                                  //   dispatchid(
                                                  //     propertyid(ele.id)
                                                  //   );
                                                  // }}
                                                ></img>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <img
                                      src={noimg}
                                      class="d-block w-100"
                                      alt="..."
                                      style={{
                                        height: "228px",
                                      }}
                                      onClick={(e) => {
                                        propertypage("/Property");
                                        window.scrollTo(0, 0);
                                        dispatchid(propertyid(ele.id));
                                      }}
                                    ></img>
                                  )}
                                </>
                              </div>
                              {/* <a
                                class="carousel-control-prev prev1"
                                href={`${"#carouselExampleControls1"}` + index}
                                role="button"
                                data-slide="prev"
                              >
                                <span
                                  class="carousel-control-prev-icon"
                                  aria-hidden="true"
                                ></span>
                                <span class="sr-only">Previous</span>
                              </a>
                              <a
                                class="carousel-control-next next1"
                                href={`${"#carouselExampleControls1"}` + index}
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  class="carousel-control-next-icon "
                                  aria-hidden="true"
                                ></span>
                                <span class="sr-only">Next</span>
                              </a> */}
                            </div>
                          </div>
                          <div
                            class="col-6 "
                            style={{
                              paddingRight: "12px",
                              paddingLeft: "12px",
                              cursor: "default",
                            }}
                          >
                            <div
                              id={`${"carouselExampleControls2"}` + index}
                              class="carousel "
                              data-interval="false"
                              style={{ height: "100%", cursor: "default" }}
                            >
                              <div
                                class="carousel-inner"
                                style={{
                                  height: "100%",
                                  borderRadius: "10px",
                                  cursor: "default",
                                }}
                              >
                                <>
                                  {ele.after.length > 0 ? (
                                    ele.after.map((element, indexele) => {
                                      return (
                                        <>
                                          <div
                                            className={`carousel-item ${
                                              indexele == 0 ? " active" : ""
                                            }`}
                                          >
                                            <div className="row">
                                              <div
                                                className="col-12"
                                                style={{
                                                  height: "230px",
                                                  padding: 0,
                                                }}
                                              >
                                                <div>
                                                  <span className="offer_tag_ba">
                                                    After
                                                  </span>
                                                </div>

                                                <img
                                                  id={indexele}
                                                  src={element}
                                                  class="d-block w-100"
                                                  alt="..."
                                                  style={{
                                                    height: "100%",
                                                  }}
                                                  // onClick={(e) => {
                                                  //   propertypage("/Property");
                                                  //   dispatchid(
                                                  //     propertyid(ele.id)
                                                  //   );
                                                  // }}
                                                ></img>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <img
                                      src={noimg}
                                      class="d-block w-100"
                                      alt="..."
                                      style={{
                                        height: "228px",
                                      }}
                                      onClick={(e) => {
                                        propertypage("/Property");
                                        window.scrollTo(0, 0);
                                        dispatchid(propertyid(ele.id));
                                      }}
                                    ></img>
                                  )}
                                </>
                              </div>
                              <a
                                class="carousel-control-prev prev3"
                                href={`${"#carouselExampleControls2"}` + index}
                                role="button"
                                data-slide="prev"
                                data-interval="false"
                              >
                                <span
                                  class="carousel-control-prev-icon"
                                  aria-hidden="true"
                                ></span>
                                <span class="sr-only">Previous</span>
                              </a>
                              <a
                                class="carousel-control-next next3"
                                href={`${"#carouselExampleControls2"}` + index}
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  class="carousel-control-next-icon "
                                  aria-hidden="true"
                                ></span>
                                <span class="sr-only">Next</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 p-0 right_card_wrap">
                      <div className="main_content1">
                        <h2
                          style={{
                            marginBottom: "3px",
                            fontWeight: "500",
                            color: "#262637",
                            fontSize: "25px",
                          }}
                        >
                          {ele.property_address}
                        </h2>
                        <div className="count1">
                          {/* <p
                            style={{
                              color: "#575F71",
                              cursor: "default",
                              margin: "10px 0",
                            }}
                          >
                            
                            {ele.description && ele.description.length > 200 ? (
                              SeemoreIndex != index ? (
                                <span>
                                  
                                  {ele.description.substring(0, 200)}......
                                  <span
                                    style={{
                                      color: "darkblue",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setSeemoreIndex(index);
                                    }}
                                  >
                                    See More
                                  </span>
                                </span>
                              ) : (
                                <span>
                                  {ele.description}......
                                  <span
                                    style={{
                                      color: "darkblue",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setSeemoreIndex(-1);
                                    }}
                                  >
                                    See Less
                                  </span>
                                </span>
                              )
                            ) : (
                              `${ele.description}`
                            )}
                          </p> */}
                          {ele.description && ele.description.length > 200 ? (
                            SeemoreIndex != index ? (
                              <>
                                <div
                                  className="text_box"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      ele.description.substring(0, 200) +
                                      ".......",
                                  }}
                                ></div>
                                <span
                                  style={{
                                    color: "darkblue",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    setSeemoreIndex(index);
                                  }}
                                >
                                  See More
                                </span>
                              </>
                            ) : (
                              <>
                                <div
                                  className="text_box"
                                  dangerouslySetInnerHTML={{
                                    __html: ele.description,
                                  }}
                                ></div>
                                <span
                                  style={{
                                    color: "darkblue",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    setSeemoreIndex(-1);
                                  }}
                                >
                                  See Less
                                </span>
                              </>
                            )
                          ) : (
                            <div
                              className="text_box"
                              dangerouslySetInnerHTML={{
                                __html: ele.description,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                      <div className="right_card_pc">
                        <div className="one">
                          <label
                            style={{
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                              // fontWeight: "700",
                              cursor: "default",
                            }}
                          >
                            Existing Property Price:&nbsp;
                          </label>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                            }}
                          >
                            £{ele.existing_property_price}
                          </span>
                        </div>

                        <div className="two">
                          <label
                            style={{
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                              // fontWeight: "700",
                              cursor: "default",
                            }}
                          >
                            Project Cost:&nbsp;
                          </label>
                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                            }}
                          >
                            £{ele.project_cost}
                          </span>
                        </div>

                        <div className="three">
                          <label
                            style={{
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                              // fontWeight: "700",
                              cursor: "default",
                            }}
                          >
                            GDV(Gross Development Value):&nbsp;
                          </label>

                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                            }}
                          >
                            £{ele.gdv}
                          </span>
                        </div>

                        <div className="four">
                          <label
                            style={{
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                              fontWeight: "700",
                              cursor: "default",
                            }}
                          >
                            Profit:&nbsp;
                          </label>

                          <span
                            style={{
                              color: "black",
                              fontSize: "17px",
                              letterSpacing: "0.7px",
                            }}
                          >
                            £{ele.gain_profit}
                          </span>
                        </div>
                        {/* <div className="bath_and_bed_count">
                          <div className="count1">
                            <span style={{ marginRight: "10px" }}>
                              {ele.property_type}
                            </span>
                            <i
                              class="fas fa-bed"
                              style={{ marginRight: "10px" }}
                            ></i>
                            <span style={{ marginRight: "10px" }}>
                              {ele.bedrooms}
                            </span>
                            <i
                              class="fas fa-bath"
                              style={{ marginRight: "10px" }}
                            ></i>
                            <span style={{ marginRight: "10px" }}>
                              {ele.bathrooms}
                            </span>
                          </div>
                        </div>   */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )
        ) : (
          <>
            <div className=" row nodata">
              <img src={nodata}></img>
            </div>
          </>
        )}

        {loader && <CircularIndeterminatecard />}

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "40px",
            // border: "1px solid"
            height: "100px",
          }}
        >
          <PaginationControl
            page={currentPage}
            between={4}
            total={datacount}
            limit={pagesize}
            next={true}
            last={false}
            // changePage={(page) => {
            //   localStorage.setItem("pageNo", "");

            //   localStorage.setItem("pageNo", page);
            //   console.log(localStorage.getItem("pageNo"), "hello", page);
            //   setPage(page);
            //   var myElement = document.getElementById("property_headerid");
            //   myElement.scrollIntoView();
            // }}
            changePage={(page) => {
              setCurrentPage(page);
              var myElement = document.getElementById("property_headerid");
              myElement.scrollIntoView();
            }}
            ellipsis={1}
          />
          <select
            class="form-select"
            id="validationCustom04"
            name="country"
            required
            style={{ width: "150px", height: "40px" }}
            // value={country}
            onChange={(e) => {
              setpagesize(e.target.value);
            }}
          >
            <option selected disabled value="">
              Show Items
            </option>
            <option value={"10"} disabled={datacount < 10}>
              10
            </option>
            <option value={"20"} disabled={datacount < 10}>
              20
            </option>
            <option value={"50"} disabled={datacount < 10}>
              50
            </option>
          </select>
        </div>
      </div>
    </>
  );
}
export default Project_Card;
