import React from 'react'
import Form from './About_Us/Form'
import Our_Team from './About_Us/Our_Team'
// import Footer from './Footer'

export default function Aboutus() {
  return (
    <div>
      <Form></Form>
      <Our_Team></Our_Team>
      {/* <Footer></Footer> */}
    </div>
  )
}

