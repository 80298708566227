// import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import Whatwedo from "./Components/Whatwedo";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutus from "./Components/Aboutus";
import Project from "./Components/Project";
import Projectdashboard from "./Components/projects/projectdashboard";

import Layout from "./Components/Layout";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Property from "./Components/property";
import CircularIndeterminate from "./Components/loader";
import AgentForm from "./Components/agentform";
import { useState } from "react";
import $ from "jquery";
import WhatsAppIcon from "../src/assets/images/whatsappicondownload.png";
import Uparrowicon from "../src/assets/images/uparrow.png";
import CookieConsent from "react-cookie-consent";

import Development from "./Components/projects/Development";
import FullyRefurb from "./Components/projects/FullyRefurb";
import CompletedProjects from "./Components/projects/CompleteProjects";

import RequireRefurb from "./Components/projects/RequireRefurb";
import Property2 from "./Components/property2";
import Intro_pic from "./Components/projects/Intro_pic";

function App(props) {
  const [loader, setloader] = useState(true);
  const [Navcolor, setNavcolor] = useState(false);
  const [viewItem, setViewItem] = useState(true);
  const loaderchange = (arg) => {
    setloader(arg);
  };

  const handleScroll = () => {
    let val = $(window).scrollTop();
    if (val > 500) {
      setNavcolor(true);
    } else {
      setNavcolor(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <div className="App">
      <Navbar Navcolor={Navcolor}></Navbar>
      {loader && loader == true ? <>{/* <CircularIndeterminate/> */}</> : ""}

      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <div className="Text">
          By clicking “Accept all cookies”, you agree Solace can store cookies
          on your device and disclose information in accordance with our{" "}
          <span
            onClick={(e) => {
              window.location.href = "/privacypolicy";
            }}
            style={{
              textDecoration: "underline",
              color: "white",
              cursor: "pointer",
            }}
          >
            Cookie Policy
          </span>
        </div>
        {/* <span style={{ fontSize: "10px" }}>Please accept the Cookies</span> */}
      </CookieConsent>

      <div
        className="main-container mx-auto"
        // style={{ width: "80vw", margin: "auto" }}
      >
        <Routes>
          <Route
            path="/"
            element={<Home setNavcolor={setNavcolor}></Home>}
          ></Route>

          {/* ------Privacy---Policy------------- */}
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>

          <Route
            path="/whatwedo"
            element={<Whatwedo setNavcolor={setNavcolor}></Whatwedo>}
          ></Route>
          <Route
            path="/Aboutus"
            element={<Aboutus setNavcolor={setNavcolor}></Aboutus>}
          ></Route>
          <Route
            path="/Projects"
            element={
              <Project
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              ></Project>
            }
          ></Route>

          <Route
            path="/projectsdashboard"
            element={
              <Projectdashboard
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              ></Projectdashboard>
            }
          ></Route>
          <Route
            path="/Contactus"
            element={<Layout setNavcolor={setNavcolor} />}
          ></Route>
          <Route
            path="/Property"
            element={
              <Property loaderchange={loaderchange} setNavcolor={setNavcolor} />
            }
          ></Route>
          {/* <Route
            path="/AgentForm"
            element={
              <AgentForm
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route> */}
           <Route
            path="/completedprojects/AgentForm"
            element={
              <AgentForm
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>
           <Route
            path="/fullyrefurbished/AgentForm"
            element={
              <AgentForm
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>
           <Route
            path="/development/AgentForm"
            element={
              <AgentForm
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>
           <Route
            path="/requiringrefurbishment/AgentForm"
            element={
              <AgentForm
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>

          <Route
            path="/development/details"
            element={
              <Property2
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>
          <Route
            path="/fullyrefurbished/details"
            element={
              <Property2
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>
          <Route
            path="/requiringrefurbishment/details"
            element={
              <Property2
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>
          <Route
            path="/completedprojects/details"
            element={
              <Property2
                loaderchange={loaderchange}
                setNavcolor={setNavcolor}
              />
            }
          ></Route>

{/* components */}
          <Route
            path="/completedprojects"
            element={
              <div>
                <Intro_pic
                  viewItem={viewItem}
                  setViewItem={setViewItem}
                ></Intro_pic>
                <RequireRefurb
                  loaderchange={loaderchange}
                  setNavcolor={setNavcolor}
                  viewItem={viewItem}
                />
              </div>
            }
          ></Route>
          <Route
            path="/requiringrefurbishment"
            element={
              <div>
                <Intro_pic
                  viewItem={viewItem}
                  setViewItem={setViewItem}
                ></Intro_pic>
                <RequireRefurb
                  loaderchange={loaderchange}
                  setNavcolor={setNavcolor}
                  viewItem={viewItem}
                />
              </div>
            }
          ></Route>
          <Route
            path="/fullyrefurbished"
            element={
              <div>
                <Intro_pic
                  viewItem={viewItem}
                  setViewItem={setViewItem}
                ></Intro_pic>
                <RequireRefurb
                  loaderchange={loaderchange}
                  setNavcolor={setNavcolor}
                  viewItem={viewItem}
                />
              </div>
            }
          ></Route>
          <Route
            path="/development"
            element={
              <div>
                <Intro_pic
                  viewItem={viewItem}
                  setViewItem={setViewItem}
                ></Intro_pic>
                <RequireRefurb
                  loaderchange={loaderchange}
                  setNavcolor={setNavcolor}
                  viewItem={viewItem}
                />
              </div>
            }
          ></Route>
        </Routes>
        <div className="bottom-container">
          <img
            id="Whatsappicon"
            src={WhatsAppIcon}
            width={100}
            height={100}
            onClick={(e) => {
              window.open(" https://wa.me/+447831489107");
            }}
          ></img>
          <div className={`left_side ${Navcolor && "active"}`}>
            <img
              src={Uparrowicon}
              width={100}
              height={100}
              onClick={(e) => {
                window.scrollTo(0, 0);
              }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
