// import "../../Stylesheets/About_Us/Form.scss";
import "../../Stylesheets/About_Us/Form.scss";
import innovative from "../../assets/images/Innovative Approach to Success.webp";
import proven from "../../assets/images/Proven Expertise.webp";
import tracking from "../../assets/images/Track record of success.webp";
import sourcing from "../../assets/images/Extensive Network.webp";
import specialist from "../../assets/images/Expert team.webp";
import high_income from "../../assets/images/High Income and Asset Appreciation Potential.webp";
import low_risk from "../../assets/images/Low risk propositions.webp";
import premier from "../../assets/images/Premier acquisition and property management.webp";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import $ from "jquery";
import icon1 from "../../assets/icons/Innovation-Icon1_page-0001.jpg";
import icon2 from "../../assets/icons/Expertise-Icon2_page-0001.jpg";
import icon3 from "../../assets/icons/Success-Icon3_page-0001.jpg";
import icon4 from "../../assets/icons/Network-Icon4_page-0001.jpg";

function Form() {
  const servicesData = [
    {
      className: "innovative_approach",
      imgSrc: icon1,
      title: "Innovative approach to success",
      body: "In addition to classic property invsetment, Deane Investment offers a distinctive business model with 'Boomerang', drawing on extensive expertise in the property industry.",
    },
    {
      className: "proven_expertise",
      imgSrc: icon2,
      title: "Proven expertise",
      body: "Our company is led by <br/> <span> Siraj Deane</span>, a seasoned professional in the property industry for more than 25 years with a history of personal investment success. <br/> He has a team of specialists with considerable experience in every aspect of property investment and buy-to-let operations. We cater to the needs of investors across the globe. <br/> By choosing Deane Investment, you can confidently enter the buy-to-let market or expand your existing portfolio. We bring professionalism, and unparalleled service.",
    },
    {
      className: "track_record_of_success",
      imgSrc: icon3,
      title: "Track record of success",
      body: "Deane Investment's impressive history of managing more than 100 successful projects ensures that your investments are in capable hands.",
    },
    {
      className: "extensive_network",
      imgSrc: icon4,
      title: "Extensive network",
      body: "Deane Investment has an established network of associates in the UK property industry, We identify the most attractive propositions for our clients. We offer niche properties with potential for high income or asset appreciation, We make your investments more rewarding whilst minimizing the chances of depreciation or loss.",
    },
    // {
    //   className: "specialist_team",
    //   imgSrc: specialist,
    //   title: "Specialist team",
    //   body: " We are assisted by a hand-picked team of specialist property acquisition and lettings consultants with vast experience in every aspect of property investment and buy-to-let operations. By choosing Deane Investment, you can confidently enter the buy-to-let market or expand your existing portfolio with the assurance of professionalism, success, and unparalleled service.",
    // },
    // {
    //   className: "high_income_and_asset",
    //   imgSrc: high_income,
    //   title: "High income and asset appreciation potential",
    //   // title: "Income & Asset Growth Prospects:",
    //   // body: "We offer niche properties with the potential for high income and asset appreciation, making your investments more rewarding.",
    //   body: "We offer niche properties with the  potential for high income and asset appreciation, making your investments more rewarding whilst minimizing the chances of depreciation or loss.",
    // },
    // {
    //   className: "low_risk_propositions",
    //   imgSrc: low_risk,
    //   title: "Low-Risk Propositions",
    //   // title: "Income & Asset Growth Prospects:",
    //   // body: "We offer niche properties with the potential for high income and asset appreciation, making your investments more rewarding.",
    //   body: "Our expertise and industry insights ensure that investments  made through Deane Investment remain low-risk, minimizing the chances of  depreciation or loss.",
    // },
    // {
    //   className: "premier_acquisition",
    //   imgSrc: premier,
    //   title: "Premier Acquisition and Management Company",
    //   // title: "Income & Asset Growth Prospects:",
    //   // body: "We offer niche properties with the potential for high income and asset appreciation, making your investments more rewarding.",
    //   body: "Deane Investment's in-depth  knowledge and broad industry connections have already solidified our reputation as  the premier buy-to-let acquisition and management company in the UK.",
    // },
  ];

  useEffect(() => {
    const observe0 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".innovative_approach").addClass("activate");
        }
      });
    });
    const observe1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".proven_expertise").addClass("activate");
        }
      });
    });
    const observe2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".track_record_of_success").addClass("activate");
        }
      });
    });
    const observe3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".extensive_network").addClass("activate");
        }
      });
    });
    // const observe4 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".specialist_team").addClass("activate");
    //     }
    //   });
    // });
    // const observe5 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".high_income_and_asset").addClass("activate");
    //     }
    //   });
    // });
    // const observe6 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".low_risk_propositions").addClass("activate");
    //     }
    //   });
    // });
    // const observe7 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".premier_acquisition").addClass("activate");
    //     }
    //   });
    // });
    observe0.observe(document.querySelector(".innovative_approach"));
    observe1.observe(document.querySelector(".proven_expertise"));
    observe2.observe(document.querySelector(".track_record_of_success"));
    observe3.observe(document.querySelector(".extensive_network"));
    // observe4.observe(document.querySelector(".specialist_team"));
    // observe5.observe(document.querySelector(".high_income_and_asset"));
    // observe6.observe(document.querySelector(".low_risk_propositions"));
    // observe7.observe(document.querySelector(".premier_acquisition"));
  }, []);

  const [econdRef, nView] = useInView({
    triggerOnce: true,
    // enterOptions,
    threshold: 0.1,
  });

  const [oneRef, oneInView] = useInView({
    triggerOnce: true,
    // enterOptions,
    threshold: 0.1,
  });
  return (
    <div className="about_us">
      {/* <div
        className={`container-fluid formdiv ${nView ? "animate" : ""}`}
        ref={econdRef}
        style={{ opacity: "0" }}
      > */}
      <div className="container-fluid formdiv ">
        <div
          className={`container form_right ${nView ? "animate" : ""}`}
          ref={econdRef}
          // style={{ opacity: "0" }}
        >
          <header className="form_header">
            ACHIEVE YOUR FINANCIAL GOALS WITH US
          </header>
          {/* <p>Achieve your financial goals with us</p> */}
          <p>
            Choose from an array of customized investments to suit your
            financial goals. Our strategies are crafted to achieve superior
            risk-adjusted returns for our clients.
          </p>
        </div>
        <div className="container text main_content">
          <h2>{/* Our Purpose */}</h2>
          {/* <p style={{ textAlign: "left" }}>
            Boomerang Buy to Let was created by Tantum Property to enable
            investors to enter the buy-to-let market or expand their existing
            portfolio in the most profitable, lowest risk, and least
            time-consuming way possible
          </p> */}
          <h2
            style={{
              position: "absolute",
              textAlign: "left",
              fontSize: "42px",
            }}
          >
            Our purpose
          </h2>
          <p
            style={{ textAlign: "left", marginTop: "125px", fontSize: "16px" }}
          >
            Deane Investment enables investors to invest in property in the most
            profitable, lowest risk, and least time-consuming way possible.{" "}
          </p>

          <h2
            // className={`h2 ${inView ? "animate" : ""}`}
            // ref={secondRef}
            style={{ fontSize: "42px" }}
          >
            Why choose us?
          </h2>

          <div className="container">
            <div className={`Inner_card_two`}>
              <div className="row" style={{ marginTop: "25px" }}>
                {servicesData.map((service, index) => (
                  // <div
                  //   className={`col-lg-3 col-md-6 col-sm-6 ${
                  //     service.className
                  //   } ${oneInView ? "animate" : ""}`}
                  //   ref={oneRef}
                  //   key={index}
                  //   style={{ opacity: "0" }}
                  // >

                  <div
                    className={`col-lg-3 col-md-6 col-sm-6 ${service.className}`}
                  >
                    <div
                      className="card card_main"
                      style={{
                        boxShadow: "0px 0px 13px 0px #000",
                      }}
                    >
                      <div className="card-body body">
                        <img src={service.imgSrc} alt={service.title} />
                        {/* <img src={service.className} alt="" /> */}
                      </div>
                      <div className="card-footer footer">
                        <h2
                          className={`Border_bottom`}
                          style={{ fontSize: "24px" }}
                        >
                          {service.title}
                        </h2>

                        {/* <p className={`Border_bottom`}>{service.body}</p> */}
                        <h7
                          style={{ fontSize: "16px !important" }}
                          className={`Border_bottom`}
                          dangerouslySetInnerHTML={{ __html: service.body }}
                        >
                          {}
                        </h7>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <ul className="special_bullet">
            <li>
              <span>Proven Expertise:</span> Our company is led by Siraj Deane,
              a seasoned professional in the property industry specialized in
              buy-to-let for over 25 years. We, along with our team of experts,
              cater to the needs of investors across the globe.
            </li>
            <br></br>
            <li>
              <span>Track Record of Success:</span> Deane Investment's
              impressive history of managing over 30 successful projects ensures
              that your investments are in capable hands.
            </li>
            <br></br>
            <li>
              <span>Extensive Network:</span> Deane Investment has an
              established network of associates within the UK property industry,
              allowing us to identify the most attractive propositions for our
              clients.
            </li>
            <br></br>
            <li>
              <span>Specialist Team:</span> We are assisted by a hand-picked
              team of specialist property acquisition and lettings consultants
              with vast experience in every aspect of buy-to-let operations
            </li>
            <br></br>
            <li>
              <span>High Income and Asset Appreciation Potential:</span> We
              offer niche properties with the potential for high income and
              asset appreciation, making your investments more rewarding.
            </li>
            <br></br>
            <li>
              <span>Low-Risk Propositions:</span> Our expertise and industry
              insights ensure that investments made through Deane Investment
              remain low-risk, minimizing the chances of depreciation or loss.
            </li>
            <br></br>
            <li>
              <span>Premier Acquisition and Management Company:</span> Deane
              Investment's in-depth knowledge and broad industry connections
              have already solidified our reputation as the premier buy-to-let
              acquisition and management company in the UK.
            </li>
            <br></br>
            By choosing Deane Investment, you can confidently enter the
            buy-to-let market or expand your existing portfolio with the
            assurance of professionalism, success, and unparalleled service.
          </ul> */}
          {/* <p style={{ marginTop: "10px", fontSize: "16px" }}>
            By choosing Deane Investment, you can confidently enter the
            buy-to-let market or expand  your existing portfolio with the
            assurance of professionalism, success, and unparalleled  service.
          </p> */}
          <h2
            style={{
              fontSize: "42px",
            }}
          >
            Our Promise
          </h2>

          <p style={{ fontSize: "16px" }}>
            We put our expertise and industry insight at your service to find
            properties with potential for high income or asset appreciation to
            secure you a profitable investment outcome.
          </p>
          <br />
        </div>
        <div className="rent-img"></div>
      </div>
    </div>
  );
}

export default Form;
