import React, { useEffect, useRef, useState } from "react";
import "../Stylesheets/Whatwedo.scss";
import Footer from "./Footer";
import Legalimg from "../assets/images/Legal.webp";
import Sourcingimg from "../assets/images/Sourcing.jpg";
import PM from "../assets/images/PropertyManagement.webp";
import Finance from "../assets/images/Finance1.webp";
import LogoImg from "../assets/images/large/boomerang-logo.jpg";
import img1 from "../assets/images/Innovative Approach to Success.webp";
import img2 from "../assets/images/Time Reclaimed, Tasks Delegated.webp";
import img3 from "../assets/images/A Holistic Approach.webp";
import img4 from "../assets/images/Value Enhancement and Expansion.webp";
import img5 from "../assets/images/Secured Income.webp";
import $ from "jquery";
import video1 from "../assets/sprite/testing.mp4";

import imagetwo from "../assets/images/whatwedo/Image 2-Completed project 2.jpg";
import icon1 from "../assets/icons/Innovation-Icon1_page-0001.jpg";
import icon2 from "../assets/icons/Expertise-Icon2_page-0001.jpg";
import icon3 from "../assets/icons/Success-Icon3_page-0001.jpg";
import icon4 from "../assets/icons/Network-Icon4_page-0001.jpg";
import video from "../assets/Boomerang_Rocket.mp4";

export default function Whatwedo() {
  const VideoRef = useRef(null);
  const [BannerView, setBannerView] = useState(false);
  useEffect(() => {
    const observe1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".second_inner_component").addClass("fade_style_wwd");
        }
      });
    });
    // const observe2 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".one").addClass("activate");
    //     }
    //   });
    // });
    const observe3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".two").addClass("activate");
        }
      });
    });
    const observe4 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".three").addClass("activate");
        }
      });
    });
    const observe5 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".four").addClass("activate");
        }
      });
    });
    const observe6 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".five").addClass("activate");
        }
      });
    });
    const observe7 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".six").addClass("activate");
        }
      });
    });
    const observe8 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".seven").addClass("activate");
        }
      });
    });
    const observe9 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".eight").addClass("activate");
        }
      });
    });
    const observe10 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".nine").addClass("activate");
        }
      });
    });
    const observe11 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".flash_image").addClass("heroAnimate");
        }
      });
    });
    observe1.observe(document.querySelector(".second_inner_component"));
    // observe2.observe(document.querySelector(".one"));
    observe3.observe(document.querySelector(".two"));
    observe4.observe(document.querySelector(".three"));
    observe5.observe(document.querySelector(".four"));
    observe6.observe(document.querySelector(".five"));
    observe7.observe(document.querySelector(".six"));
    observe8.observe(document.querySelector(".seven"));
    observe9.observe(document.querySelector(".eight"));
    observe10.observe(document.querySelector(".nine"));
    // observe11.observe(document.querySelector(".flash_image"));
  }, []);
  const TimeUpdate = (e) => {
    // console.log(e.target)
    // console.log(e.target.duration, e.target.currentTime)

    if (e.target.currentTime > e.target.duration - 0.01) {
      setBannerView(true);
      // .off('timeupdate'); // <--
    }
  };
  const myCallback = (e) => {
    //   console.log(e)
  };
  return (
    <div className={`Whatwedo_wrapper`}>
      <video
        id="vid"
        muted="muted"
        playsInline
        src={video}
        controls={false}
        width={"100%"}
        height={"200%"}
        autoPlay
        ref={VideoRef}
        onEnded={(e) => myCallback(e)}
        onTimeUpdate={(e) => TimeUpdate(e)}
        className="whatWeDoVideo"
      ></video>
      {BannerView && (
        <div className={`flash_inner_content`}>
          <h1>THE BOOMERANG DIFFERENCE</h1>
          <p>
            We'd like to explain what sets Boomerang apart and the exceptional
            benefits we can provide for you.
          </p>
          <p>
            efully selected properties can make attractive capital gains.
            Buy-to-let property has proven to be a sound investment for most
            landlords for a decade or more – with its own unique
            characteristics.
          </p>
        </div>
      )}

      <div className="what-we-do-new-img">
        <img src={imagetwo} className="what-we-img"></img>
      </div>
      <div className={`second_inner_component`}>
        <div className={`Inner_card_two`}>
          <h1>OUR SERVICE</h1>
          <p>
            We offer a range of services to meet your investment needs, We guide
            you towards financial success and help you manage the investment
            risks in this attractive sector.
          </p>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 six">
              <div className="card">
                <div className="card-body">
                  <img src={icon1}></img>
                </div>
                <div className="card-footer">
                  <p className={`Border_bottom`}>Sourcing</p>
                  <ul>
                    <li>Strategic property market analysis</li>
                    <li>Exclusive access</li>
                    <li>Market expertise</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 seven">
              <div className="card">
                <div className="card-body">
                  <img src={icon4}></img>
                </div>
                <div className="card-footer">
                  <p className={`Border_bottom`}>
                    Introducing advisers on finance
                  </p>
                  <ul>
                    <li>Tailored solutions</li>
                    <li>Trusted partners</li>
                    <li>Transparent fees</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 eight">
              <div className="card">
                <div className="card-body">
                  <img src={icon4}></img>
                </div>
                <div className="card-footer">
                  <p className={`Border_bottom`}>Introducing legal advisers</p>
                  <ul>
                    <li>Expert guidance</li>
                    <li>Ensured compliance</li>
                    <li>Hassle-free process</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 nine">
              <div className="card">
                <div className="card-body">
                  <img src={icon2}></img>
                </div>
                <div className="card-footer">
                  <p className={`Border_bottom`}>Property management</p>
                  <ul>
                    <li>Safeguarding client money</li>
                    <li>Property maintenance</li>
                    <li>Regulatory compliance</li>
                    <li>Periodic property inspections</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`Inner_card_one`}>
          <h1 style={{ marginBottom: "25px", color: "#011e60" }}>
            HOW OUR CLIENTS BENEFIT
          </h1>
          <div className="what-we-do-new-img-icon">
            <img src={icon4} className="what-we-img"></img>
          </div>
          {/* <div className="row innovative">
            <div className="col-lg-3 col-md-4 one">
              <div
                className="card"
                style={{ boxShadow: "0px 0px 13px 0px #000" }}
                // height: "670px"
              >
                <div className="card-body">
                  <img
                    src={img1}
                    className="w-100"
                    style={{ width: "150px" }}
                  ></img>
                </div>
                <div className="card-footer" style={{}}>
                  <b className="header">Innovative approach to success</b>
                  <p>
                    Deane Investment offers a distinctive business model
                    Boomerang Buy-to-Let, deploying its extensive expertise in
                    the property industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 two time">
              <div
                className="card"
                style={{ boxShadow: "0px 0px 13px 0px #000" }}
              >
                <div className="card-body">
                  <img
                    src={img2}
                    className="w-100"
                    style={{ width: "150px" }}
                  ></img>
                </div>
                <div className="card-footer" style={{}}>
                  <b className="header">Time reclaimed</b>
                  <p>
                    Our team of experts can oversee the whole investment
                    process, enabling our clients to save their valuable time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 three holistic">
              <div
                className="card "
                style={{ boxShadow: "0px 0px 13px 0px #000" }}
              >
                <div className="card-body body">
                  <img
                    src={img3}
                    className="w-100"
                    style={{ width: "150px", height: "100%" }}
                  ></img>
                </div>
                <div
                  className="card-footer footer "
                  // style={{ height: "370px" }}
                >
                  <b className="header">A holistic approach</b>
                  <p>
                    Fostering prosperous investment growth, our experts assess
                    individual requirements and choose properties with
                    substantial rental yields to supplement, existing
                    portfolios.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 four Value">
              <div
                className="card"
                style={{ boxShadow: "0px 0px 13px 0px #000" }}
              >
                <div className="card-body">
                  <img
                    src={img4}
                    className="w-100"
                    style={{ width: "150px" }}
                  ></img>
                </div>
                <div className="card-footer" style={{ height: "370px" }}>
                  <b className="header">Value enhancement and expansion</b>
                  <p>
                    Strategic enhancements like refurbishments, loft conversion,
                    dormer extension, single and double storey - side & rear
                    extensions enhance the value of the property, enabling
                    reinvestment in further opportunities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 five Property">
              <div
                className="card"
                style={{ boxShadow: "0px 0px 13px 0px #000" }}
              >
                <div className="card-body">
                  <img
                    src={img5}
                    className="w-100"
                    style={{ width: "150px" }}
                  ></img>
                </div>
                <div className="card-footer" style={{}}>
                  <b className="header">Secured income</b>
                  <p>
                    If you want a property for its yield, our Property
                    Management team ensures regulatory compliance and
                    uninterrupted long-term rental income, mitigating concerns
                    clients have about managing properties on their own.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row innovative">
            <div className="col-lg-6 col-md-6 two time">
              <div className="card-body">
                <p>
                  <strong>1) Time reclaimed</strong>
                </p>
                <p>
                  Our team of experts can oversee the whole investment process,
                  enabling our clients to save their valuable time.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 three holistic">
              <div className="card-body">
                <p>
                  <strong>2) A holistic approach</strong>
                </p>
                <p>
                  Fostering prosperous investment growth, our experts assess
                  individual requirements and choose properties with substantial
                  rental yields to supplement, existing portfolios.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 four Value">
              <div className="card-body">
                <p>
                  <strong>3) Value enhancement and expansion</strong>
                </p>
                <p>
                  Strategic enhancements like refurbishments, loft conversion,
                  dormer extension, single and double storey - side & rear
                  extensions enhance the value of the property, enabling
                  reinvestment in further opportunities.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 five Property">
              <div className="card-body">
                <p>
                  <strong>4) Secured income</strong>
                </p>
                <p>
                  If you want a property for its yield, our Property Management
                  team ensures regulatory compliance and uninterrupted long-term
                  rental income, mitigating concerns clients have about managing
                  properties on their own.
                </p>
              </div>
            </div>
          </div>

          <p style={{ marginTop: "50px" }}>
            Embark on this journey with Deane Investment.
          </p>
        </div>
      </div>
      {/* <div className='boomerang_logo'>
         <img src={LogoImg}></img>
         <p>Boosts the asset value within short period</p>
         <p>High rental yield</p>
         <p>Unlock your deposit</p>
      </div> */}
      <Footer></Footer>
    </div>
  );
}
