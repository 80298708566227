import React from "react";
import Banner from "./Banner";
import Contact from "./Contact";
import Footer from "./Footer";

function Layout() {
  return (
    <>
      <Banner />
      <Contact />
      <div className="">
        <Footer />
      </div>
    </>
  );
}

export default Layout;
