import React, { useEffect } from "react";
import "../Stylesheets/Footer.scss";
import insta from "../assets/images/vecteezy_instagram-logo-png-instagram-icon-transparent_18930415_795.webp";
import facebook from "../assets/images/vecteezy_facebook-png-icon_16716481_104.webp";
import linkedin from "../assets/images/vecteezy_linkedin-logo-png-linkedin-icon-transparent-png_18930587_72.webp";
import $ from "jquery";
import CopyrightIcon from "@mui/icons-material/Copyright";
export default function Footer() {
  useEffect(() => {
    const observe1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".Footer_wrapper").addClass("activate1");
        }
      });
    });
    // const observe2 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       $(".Kasi").addClass("activate");
    //     }
    //   });
    // });

    observe1.observe(document.querySelector(".Footer_wrapper"));
    // observe2.observe(document.querySelector(".Kasi"));
  }, []);

  return (
    <>
      <div className="footerpart">
        <div className={`Footer_wrapper`}>
          <div className={`Footer_inner_wrapper`}>
            <h1>Begin your journey with Boomerang today</h1>
            <p>
              Whatever your previous experience of buy-to-let investment,
              Boomerang Buy to Let can help you to create a profitable
              investment portfolio or take your existing portfolio to the next
              level.
            </p>
            <p>
              Contact us now for a prompt, insightful response from an
              experienced buy-to-let portfolio manager, with no obligation.
            </p>
            <div className="my-2">
              <a href="/privacypolicy">Privacy Policy and Terms of Service</a>
            </div>
            <a
              onClick={(e) => {
                window.open("https://www.instagram.com/deane_investment/");
              }}
            >
              <img
                className="active4"
                src={insta}
                style={{ width: "40px", cursor: "pointer" }}
              ></img>
            </a>
            <a
              onClick={(e) => {
                window.open("https://www.facebook.com/deaneinvestment");
              }}
            >
              <img
                className="active3"
                src={facebook}
                style={{ width: "26px", cursor: "pointer" }}
              ></img>
            </a>
            <a
              onClick={(e) => {
                window.open(
                  "https://www.linkedin.com/company/deane-investment/mycompany/verification/?viewAsMember=true"
                );
              }}
            >
              <img
                className="active4"
                src={linkedin}
                style={{ width: "40px", cursor: "pointer" }}
              ></img>
            </a>
          </div>
        </div>

        <div className="conatiner copyRights ">
          <p>
            <CopyrightIcon /> copyright 2022 Deane Management All Rights
            Reserved.{" "}
          </p>
          {/* <a href="/privacypolicy">Privacy Policy and Terms of Service</a> */}
        </div>
      </div>
    </>
  );
}
